import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_target_user_info = _resolveComponent("target-user-info");
  const _component_call_opecation = _resolveComponent("call-opecation");
  return _ctx.status !== 'calling' ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "call-readly",
    style: _normalizeStyle({
      background: _ctx.status === 'calling' ? '' : `url('${_ctx.targetUserInfo.middleIcon}') no-repeat center / cover #000000e6`
    })
  }, [_createVNode(_component_target_user_info, {
    targetUserInfo: _ctx.targetUserInfo
  }, null, 8, ["targetUserInfo"]), _createVNode(_component_call_opecation, {
    status: _ctx.status,
    onOnCancelInvite: _cache[0] || (_cache[0] = $event => _ctx.handleDispatchEmitEvent('onCancelInvite')),
    onOnRejectInvited: _cache[1] || (_cache[1] = $event => _ctx.handleDispatchEmitEvent('onRejectInvited')),
    onOnAcceptInvited: _cache[2] || (_cache[2] = $event => _ctx.handleDispatchEmitEvent('onAcceptInvited'))
  }, null, 8, ["status"])], 4)) : _createCommentVNode("", true);
}