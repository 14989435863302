import { handleAsyncInjectScript } from '@/utils/utils';
import { defineComponent, ref, watchEffect } from 'vue';
import * as URL from 'url-parse';
export default defineComponent({
  name: 'svga-player',
  props: ['modelValue', 'url', 'loops'],
  emits: ['update:modelValue', 'onFinished'],
  setup(props, _ref) {
    let {
      emit
    } = _ref;
    const show = ref(false);
    watchEffect(() => {
      show.value = props.modelValue || false;
    });
    watchEffect(() => {
      emit('update:modelValue', show.value);
    });
    return {
      show
    };
  },
  computed: {
    adaptedUrl() {
      const urlOptions = new URL(this.url);
      return this.url.replace(`${urlOptions.protocol}//${urlOptions.host}/`, '/static/');
    }
  },
  mounted() {
    handleAsyncInjectScript('/library/svga.min.js', 'svga-library').then(() => {
      this.handleTryPlaySvgaAnimation();
    });
  },
  watch: {
    show(newValue, oldValue) {
      if (newValue && !oldValue) {
        this.handleTryPlaySvgaAnimation();
      }
    }
  },
  methods: {
    handleTryPlaySvgaAnimation() {
      try {
        this.handlePlaySvgaAnimation();
      } catch (e) {
        console.log(e);
      }
    },
    handlePlaySvgaAnimation() {
      const player = new window.SVGA.Player('#svga-player');
      const parser = new window.SVGA.Parser('#svga-player');
      player.loops = typeof this.loops === 'undefined' ? 1 : this.loops;
      player.onFinished(() => {
        this.show = false;
        this.$emit('onFinished');
      });
      parser.load(this.adaptedUrl, videoItem => {
        player.setVideoItem(videoItem);
        player.startAnimation();
      });
    }
  }
});