import { handleTriggerRenderToBody } from '@/utils/utils';

import AlertModal from './components/alert-modal.vue';
import SvgaPlayerModal from './components/svga-player-modal.vue';

export function showCustomAlertModal(params, id = undefined) {
    return new Promise((resolve, reject) => {
        let closer = null;

        const options = {
            ...params,
            modelValue: true,
            'onUpdate:modelValue': (visible) => {
                if (!closer || visible) {
                    return;
                }
                closer();
                reject();
            },
            onOk: () => {
                closer();
                resolve();
            },
        };
        closer = handleTriggerRenderToBody(AlertModal, options, id).closer;
    });
}

export function showSvgaPlayerModal(params, id = undefined) {
    let closer = null;

    const options = {
        ...params,
        modelValue: true,
        'onUpdate:modelValue': (visible) => {
            if (!closer || visible) {
                return;
            }
            closer();
        },
    };

    closer = handleTriggerRenderToBody(SvgaPlayerModal, options, id).closer;
}
