import { request } from '@/utils/network';
import { getSystemLanguage } from 'packages/kk-tools-v1.0.0/dist/esm/index';

// 获取礼物列表
export function handleGetGiftAjax() {
    const language = getSystemLanguage() === 'zh-CN' ? 'en-US' : getSystemLanguage();
    return request('post', '/point/get-gifts-v2', { language, type: 2 });
}

// 发送礼物
export function handleSendGiftAjax(params) {
    return request('post', '/point/send-gift', params, {}, true);
}

// 是否为vip
export function handleGetIsVipAjax(params) {
    return request('post', '/point/member/get-message', params);
}

// 商品列表
export function handleFetchProductionsAjax(params) {
    return request('post', '/point/goods/all-list', params);
}

// 领取免费商品
export function handleReceiveActivityRewardAjax(params) {
    return request('post', '/point/goods/draw-activity-reward', params);
}

// 获取 vip 商品
export function handleFetchVipGoodsAjax() {
    return request('post', '/point/goods/get-list-v2', { type: '3' });
}

// 获取支付方式
export function handleFetchPayWayAjax() {
    return request('post', '/point/pay/get-type', {}, {}, true);
}

// 非 googlepay 方式支付
export function handleGeneralWayPayAjax(params) {
    return request('post', '/point/v2/pay/create-payment-url', params);
}

// 是否展示匹配活动商品
export function handleIsShowMatchGoodsAjax(params) {
    return request('post', '/point/pay/show-match-goods', params);
}

// 音视频检查钻石是否足够
export function handleCheckoutIfAllowCallAjax(params) {
    return request('post', '/point/stone/allow-call', params, {}, true);
}

// 扣除用户聊天次数
export function handleGetIsAllowSendMsg(params) {
    return request('post', '/point/talk/deduct-talk-number-v2', params, {}, true);
}
