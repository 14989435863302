import { defineComponent } from 'vue';
import { makeI18nT } from 'languages/index';
export default defineComponent({
  name: 'pay-popup-btn',
  props: ['priceText'],
  setup() {
    const {
      t
    } = makeI18nT();
    return {
      t
    };
  },
  methods: {
    handleToPay() {
      this.$emit('pay');
    }
  }
});