import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3a1dcfb5"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "call-chat-window",
  ref: "callChatWindow"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_call_chat_record = _resolveComponent("call-chat-record");
  return _openBlock(), _createElementBlock("view", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.textLiveMessages, item => {
    return _openBlock(), _createBlock(_component_call_chat_record, {
      key: item.getMessagePayload().idClient,
      targetUserInfo: _ctx.targetUserInfo,
      msgItem: item
    }, null, 8, ["targetUserInfo", "msgItem"]);
  }), 128))], 512);
}