import { defineComponent } from 'vue';
import Bootstrap from '@/providers/bootstrap';
import RTCVideoEntry from '@/components/av-rtc-group/components/rtc-video-entry.vue';
import 'vant/es/dialog/style';
import 'vant/es/toast/style/index';
import 'vant/es/uploader/style/index';
export default defineComponent({
  name: 'App',
  setup() {
    const isShowVideoLayer = window.location.pathname !== '/login';
    return {
      isShowVideoLayer
    };
  },
  components: {
    'rtc-video-entry': RTCVideoEntry
  },
  mounted() {
    if (!this.$refs.RTCVideoRef) {
      return;
    }
    Bootstrap.videoControlRef = this.$refs.RTCVideoRef;
  }
});