import dayjs from 'dayjs';

import { getUserInfoFromStorage } from '@/utils/utils';
import { getTouristUniqueDeviceId } from '@/providers/tourist';
import { handleKKReportLogAjax, handleReportErrorDetailAjax } from '@/services/notification';

// pixel 埋点
export const FBR_EVENTS = {
    CompleteRegistration: 'CompleteRegistration',
    Purchase: 'Purchase',
    CustomPurchase: 'custom_purchase',
    InitiateCheckout: 'InitiateCheckout',
    CustomInitiateCheckout: 'custom_initiate_checkout',
};

// firebase 埋点
export const FIREBASE_EVENTS = {
    SignUp: 'sign_up',
    BeginCheckout: 'begin_checkout',
    Purchase: 'purchase',
    CustomBeginCheckout: 'custom_begin_checkout',
    CustomPurchase: 'custom_purchase',
};

// 操作日志打点上报
export const KK_EVENTS = {
    SIGN_UP: 'SIGN_UP',
    PURCHASE: 'PURCHASE',
    INITIATE_CHECKOUT: 'INITIATE_CHECKOUT',

    IM_ERROR: 'IM_ERROR',
    IM_CONNECTED: 'IM_CONNECTED',
    IM_DISCONNECTED: 'IM_DISCONNECTED',
    IM_OFFLINE_MESSAGES: 'IM_OFFLINE_MESSAGES',
    IM_WILL_RECONNECTING: 'IM_WILL_RECONNECTING',
    AGORA_CHANNEL_STATUS: 'AGORA_CHANNEL_STATUS',

    FACEBOOK_REPORT_ERROR: 'FACEBOOK_REPORT_ERROR',
};

function handleAppendUserInfo(payload) {
    const userInfo = getUserInfoFromStorage();
    return { ...payload, userStringId: userInfo.userStringId, time: dayjs().format('YYYY-MM-DD HH:mm:ss') };
}

export function KKReporter(event, payload) {
    const detail = { ...handleAppendUserInfo(payload), ua: window.navigator.userAgent };
    return handleKKReportLogAjax({ type: event, deviceId: getTouristUniqueDeviceId(), scene: 'activity', code: 0, detail: JSON.stringify(detail) });
}

export function KKErrorReporter(payload) {
    const detail = { ...handleAppendUserInfo(payload), ua: window.navigator.userAgent };
    return handleReportErrorDetailAjax({ type: 'ERROR', deviceId: getTouristUniqueDeviceId(), scene: 'activity', code: 0, detail: JSON.stringify(detail) });
}

export function FBReporter(event, payload, isCustom = false) {
    if (!window.fbq || !window.fbq.callMethod) {
        KKReporter(KK_EVENTS.FACEBOOK_REPORT_ERROR, { fbPayload: { event, payload, isCustom } });
        return;
    }
    window.fbq(isCustom ? 'trackCustom' : 'track', event, handleAppendUserInfo(payload));
}

export function FireBaseReporter(event, payload) {
    if (!window.gtag) {
        return;
    }
    window.gtag('event', event, handleAppendUserInfo(payload));
}
