export const USER_INFO = 'USER_INFO';
export const LOGIN_TYPE = 'LOGIN_TYPE';
export const AUTHORIZATION = 'AUTHORIZATION';
export const MATCH_INTERRUUPT = 'MATCH_INTERRUUPT';
export const MATCH_INTERRUUPT_GENDER = 'MATCH_INTERRUUPT_GENDER';
export const IM_AUTHORIZATION = 'IM_AUTHORIZATION';
export const TOURIST_DEVICE_ID = 'TOURIST_DEVICE_ID';
export const IMG_RESOURCE_DOMAIN = 'https://acc.pickume.com/WoooWeb';
export const MATCH_GUIDE = 'MATCH_GUIDE';
export const MATCH_RECORD_SHOW_GUIDE = 'MATCH_RECORD_SHOW_GUIDE';
export const KF_DISABLED_BTNS = 'KF_DISABLED_BTNS';
export const KF_FQA_ID = 'KF_FQA_ID';
