// 应用初始化
import { router } from '@/router/index';
import { makeI18nT } from 'languages/index';
import { getRequestHeaders } from '@/utils/network';
import { handleGetAgoraAuthTokenAjax } from '@/services/login';
import { handleCheckoutIfAllowCallAjax } from '@/services/point';
import { handleOnlineUpdateStateAjax } from '@/services/social';
import { AUTHORIZATION, IM_AUTHORIZATION } from '@/utils/consts';
import { getUserInfoFromStorage, handleForbidMobilePullFreshWithSafari } from '@/utils/utils';
import { forbidMobileScaleEvents, isMobile, forceJumpLink } from 'packages/kk-tools-v1.0.0/dist/esm/index';
import { FBR_EVENTS, FIREBASE_EVENTS, KK_EVENTS, KKReporter, FBReporter, FireBaseReporter, KKErrorReporter } from '@/providers/reporter';

// 后台线程
import * as Comlink from 'comlink';

import Matcher from './matcher';
import EventEmitter from 'eventemitter3';
import DeductionRule from './deduction';

export default class Bootstrapt {
    static imInstance = null;

    static matchInstance = null;

    static eventer = new EventEmitter();

    static videoControlRef = null;

    static webWorker = Comlink.wrap(new window.Worker(new URL('../web-worker.js', import.meta.url)));

    async init() {
        this.initBrowserActivity().handleTryAutoClose().handleTryToLogin();

        // 已登陆 - 初始化IM
        if (window.localStorage.getItem(AUTHORIZATION)) {
            this.initHeartbeat().initIM();
        }

        await Bootstrapt.webWorker.init(process.env.VUE_APP_API_DOMAIN, getRequestHeaders());

        return Promise.resolve(this);
    }

    // IM 模块初始化
    initIM() {
        const userInfo = getUserInfoFromStorage();
        const IMToken = window.localStorage.getItem(IM_AUTHORIZATION) || '';

        const options = {
            token: IMToken,
            account: userInfo.imId,
            lbsUrl: process.env.VUE_APP_YX_LBS,
            appKey: process.env.VUE_APP_YX_APPKEY,
            defaultLink: process.env.VUE_APP_YX_LINK,
        };

        Bootstrapt.imInstance = new window.KKYIM.AudioVideoCall();
        Bootstrapt.imInstance.init(options, {
            userInfo,
            i18nT: makeI18nT().t,
            agoras: {
                appkey: process.env.VUE_APP_AGORA_APPKEY,
                outputElementId: 'rtc-video-output',
                selfOutputElementId: 'rtc-video-small-view',
            },
            initAgoraToken: handleGetAgoraAuthTokenAjax,
            deductionRule: DeductionRule.getInstance(),
            beforeAudioVideoCallLogicStart: this.beforeAudioVideoCallLogicStart.bind(this),
            beforeAudioVideoReceivedLogicStart: this.beforeAudioVideoReceivedLogicStart.bind(this),
        });

        Bootstrapt.matchInstance = Matcher.getMatchInstance();

        // 订阅通知
        this.handleIMReportListener();
    }

    // 拨打音视频前校验
    beforeAudioVideoCallLogicStart(type, imId, targetUserInfo) {
        // 匹配不校验
        if (Bootstrapt.matchInstance.getStatus() !== 'readly') {
            return Promise.resolve(true);
        }

        // 打点上报
        KKErrorReporter({ targetUserInfo, imId, event: null, tip: '即将开启音视频通话', url: window.location.href });

        return handleCheckoutIfAllowCallAjax({ type: type === 1 ? 'voiceChat' : 'videoChat', targetImId: imId })
            .then((result) => {
                if (result.code !== 0) {
                    return false;
                }
                // 钻石不够
                if (result.data.enough === false) {
                    return router.push('/diamonds?tip=1') && false;
                }
                return result.data.allowCall;
            });
    }

    // 接受邀请前校验
    beforeAudioVideoReceivedLogicStart(event) {
        const [status, targetUserInfo] = [Bootstrapt.matchInstance.getStatus(), Bootstrapt.matchInstance.getTargetUserInfo()];

        // 正在匹配流程 -》直接退出
        if (['matching', 'calling'].indexOf(status) > -1 || (status === 'waiting' && targetUserInfo.imId !== event.from)) {
            return Promise.resolve(false);
        }

        // 匹配不做接通校验
        if (status === 'waiting' && targetUserInfo.imId === event.from) {
            return Promise.resolve(true);
        }

        // 打点上报
        KKErrorReporter({ targetUserInfo, event, tip: '接受邀请前校验', url: window.location.href, imId: targetUserInfo?.imId || event.from });

        // 接通校验
        return handleCheckoutIfAllowCallAjax({ type: 'videoChat', targetImId: targetUserInfo?.imId || event.from })
            .then((result) => {
                if (result.code !== 0) {
                    return false;
                }
                // 钻石不够
                if (result.data.enough === false) {
                    return router.push('/diamonds?tip=1') && false;
                }
                return result.data.allowCall;
            });
    }

    handleIMReportListener() {
        Bootstrapt.imInstance.handleListenYimEvents(window.KKYIM.YimDispatchEvent.REPORT_NOTICE, ({ payload }) => {
            // pixel 上报
            if (payload.data.name === 'purchase' && payload.data.paySuccess) {
                FBReporter(FBR_EVENTS.Purchase, payload.data.property || {});
                FBReporter(FBR_EVENTS.CustomPurchase, payload.data.property || {}, true);

                KKReporter(KK_EVENTS.PURCHASE, payload.data.property || {});
            }

            // firebase 上报
            if (payload.data.firebase && payload.data.name === 'purchase' && payload.data.paySuccess) {
                FireBaseReporter(FIREBASE_EVENTS.Purchase, payload.data.property || {});
                FireBaseReporter(FIREBASE_EVENTS.CustomPurchase, payload.data.property || {});
            }
        });
    }

    // 初始化行为
    initBrowserActivity() {
        if (isMobile()) {
            forbidMobileScaleEvents();
            handleForbidMobilePullFreshWithSafari();
        }
        return this;
    }

    // 自动跳转登陆
    handleTryToLogin() {
        if (window.localStorage.getItem(AUTHORIZATION) || window.location.pathname === '/login') {
            return;
        }
        forceJumpLink('/login');
    }

    // 尝试自动关闭
    handleTryAutoClose() {
        return window.opener ? (window.close() || this) : this;
    }

    // 心跳初始化
    initHeartbeat() {
        const heartbeatRequest = () => handleOnlineUpdateStateAjax().then((result) => result);

        heartbeatRequest();

        setInterval(() => heartbeatRequest(), 1000 * 60);

        return this;
    }
}
