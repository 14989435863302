/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { request } from '@/utils/network';

// 是否未注册
export function handleIsNotRegistedAjax(params) {
    return request('post', '/account/user/quick-sign-up', params, {}, true).then((result) => ({ ...result, data: result.code === 4 }));
}

// 一键注册
export function handleTouristSignUpAjax(params) {
    return request('post', '/account/user/quick-sign-up', params, {}, true);
}

// google 注册
export function handleGoogleSignUpAjax(params) {
    return request('post', '/account/third-party/google/sign-in', params);
}

// Facebook 注册
export function handleFacebookSignUpAjax(params) {
    return request('post', '/account/third-party/facebook/sign-in', params);
}

// apple 注册
export function handleAppleSignUpAjax(params) {
    return request('post', '/account/third-party/apple/web-sign-in', params);
}

// google 绑定
export function handleGoogleBindAjax(params) {
    return request('post', '/account/third-party/bind/google', params);
}

// Facebook 绑定
export function handleFacebookBindAjax(params) {
    return request('post', '/account/third-party/bind/facebook', params);
}

// apple 绑定
export function handleAppleBindAjax(params) {
    return request('post', '/account/third-party/bind/web-apple', params);
}

// 三方注册、信息补全
export function handleUserInfoCompleteAjax(params) {
    return request('post', '/account/user/complete', params);
}

// 上传临时头像
export function handleUploadTempAvatarAjax(params) {
    const formData = new FormData();

    for (const prop in params) {
        formData.append(prop, params[prop]);
    }
    return request('post', '/account/user/upload-temp-icon', formData, { 'Content-Type': 'multipart/form-data' });
}

// 获取手机号区号
export function handleFetchMobileZoneCodeAjax() {
    return request('post', '/account/user/anchor-country-calling-code', {});
}

// 发送手机验证码
export function handleSendMobileCodeAjax(params) {
    return request('post', '/account/user/send-verification-code-to-phone', params);
}

// 手机号登陆
export function handlePhoneSignUpAjax(params) {
    return request('post', '/account/third-party/v2/phone/sign-in', params, {}, true);
}

// 手机号绑定
export function handlePhoneBindAjax(params) {
    return request('post', '/account/third-party/bind/phone', params);
}

// 邮箱验证码
export function handleSendEmailCodeAjax(params) {
    return request('post', '/account/user/send-verification-code-to-email', params);
}

// 邮箱登陆
export function handleEmailSignUpAjax(params) {
    return request('post', '/account/third-party/email/sign-in', params, {}, true);
}

// 邮箱绑定
export function handleEmailBindAjax(params) {
    return request('post', '/account/user/bind-email', params);
}

// 绑定列表
export function handleFetchBindInfoAjax() {
    return request('post', '/account/third-party/get-bind', {}, {}, true);
}

// 获取声网鉴权token
export function handleGetAgoraAuthTokenAjax(channelName, userId) {
    return request('post', '/account/user/get-agora-auth-token', { agoraUid: userId, channelName }, {}, true).then((result) => (result.code !== 0 ? '' : result.data.agoraAuthToken));
}
