import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2cde53b0"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "gift-popup-wrap"
};
const _hoisted_2 = {
  class: "gift-popup"
};
const _hoisted_3 = {
  class: "gift-popup-content"
};
const _hoisted_4 = {
  class: "gift-popup-footer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_gift_swipe = _resolveComponent("gift-swipe");
  const _component_gift_footer = _resolveComponent("gift-footer");
  const _component_van_popup = _resolveComponent("van-popup");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_popup, {
    show: _ctx.visible,
    "onUpdate:show": _cache[0] || (_cache[0] = $event => _ctx.visible = $event),
    round: "",
    position: "bottom"
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_gift_swipe, {
      giftGroups: _ctx.giftGroups,
      onOnSelect: _ctx.handleSelect
    }, null, 8, ["giftGroups", "onOnSelect"])]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_gift_footer, {
      stoneNumber: _ctx.stoneNumber,
      onOnSendGift: _ctx.handleSendGift
    }, null, 8, ["stoneNumber", "onOnSendGift"])])])]),
    _: 1
  }, 8, ["show"])]);
}