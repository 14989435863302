import { showToast } from 'vant';
import { makeI18nT } from 'languages/index';
import { defineComponent, ref } from 'vue';
import { useImInstance } from '@/hooks/useImInstance';
import GiftPopup from '@/components/gift-popup/index.vue';
export default defineComponent({
  name: 'chat-tool-bar',
  props: ['targetUserInfo'],
  setup() {
    const {
      t
    } = makeI18nT();
    const {
      imInstance
    } = useImInstance();
    const [inputText, giftVisible] = [ref(''), ref(false)];
    return {
      imInstance,
      inputText,
      giftVisible,
      t
    };
  },
  components: {
    'gift-popup': GiftPopup
  },
  methods: {
    handleTriggerGiftPopup() {
      this.giftVisible = true;
    },
    handleSendMessage() {
      if (!this.inputText) {
        showToast({
          message: this.t('global.c-av-rtc-group#请填写发送内容')
        });
        return;
      }
      this.imInstance.handleSendLiveTextMessage('p2p', this.targetUserInfo.imId, this.inputText).then(() => {
        this.inputText = '';
      });
    }
  }
});