import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-155eaa19"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "call-like-wrap"
};
const _hoisted_2 = {
  key: 0,
  class: "call-like-notice"
};
const _hoisted_3 = {
  class: "call-like-notice-img"
};
const _hoisted_4 = {
  class: "call-like-notice-text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_icon = _resolveComponent("van-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: "call-like",
    onClick: _cache[0] || (_cache[0] = function () {
      return _ctx.handleLike && _ctx.handleLike(...arguments);
    })
  }, [_createElementVNode("div", {
    class: _normalizeClass(['call-like-inner', _ctx.isLiked ? 'selected' : ''])
  }, null, 2)]), _ctx.likeText ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_van_icon, {
    name: "like"
  })]), _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.likeText), 1)])) : _createCommentVNode("", true)]);
}