import { v4 as uuidv4 } from 'uuid';
import { TOURIST_DEVICE_ID } from '@/utils/consts';

export function makeTouristUniqueDeviceId() {
    return uuidv4();
}

export function getTouristUniqueDeviceId() {
    return window.localStorage.getItem(TOURIST_DEVICE_ID) || '';
}

export function saveTouristUniqueDeviceId(deviceId) {
    if (getTouristUniqueDeviceId()) {
        return;
    }
    window.localStorage.setItem(TOURIST_DEVICE_ID, deviceId);
}
