import { showToast } from 'vant';
import { makeI18nT } from 'languages/index';
import { defineComponent, ref, watchEffect } from 'vue';
import { useGifts } from './hooks/useGifts';
import { useDiamonds } from '@/hooks/useDiamonds';
import { useImInstance } from '@/hooks/useImInstance';
import { handleSendGiftAjax } from '@/services/point';
import GiftSwipe from './components/gift-swipe.vue';
import GiftFooter from './components/gift-footer.vue';
const {
  ImMsgType
} = window.KKYIM;
export default defineComponent({
  name: 'gift-popup',
  emits: ['update:modelValue'],
  props: ['modelValue', 'targetUserInfo', 'isVip', 'isLiveScene'],
  setup(props, _ref) {
    let {
      emit
    } = _ref;
    const [loading, visible] = [ref(false), ref(false)];

    // eslint-disable-next-line object-curly-newline
    const {
      gifts,
      selectedGift,
      giftGroups,
      handleUpdateSelectGift
    } = useGifts();
    const {
      stoneNumber,
      handleFetchStoneNumber
    } = useDiamonds();
    const {
      imInstance
    } = useImInstance();
    const {
      t
    } = makeI18nT();
    watchEffect(() => {
      visible.value = props.modelValue;
    });
    watchEffect(() => {
      emit('update:modelValue', visible.value);
    });
    return {
      loading,
      visible,
      gifts,
      selectedGift,
      giftGroups,
      handleUpdateSelectGift,
      stoneNumber,
      handleFetchStoneNumber,
      imInstance,
      t
    };
  },
  components: {
    'gift-swipe': GiftSwipe,
    'gift-footer': GiftFooter
  },
  watch: {
    visible(newValue) {
      return newValue && this.handleFetchStoneNumber();
    }
  },
  methods: {
    handleSelect(data) {
      this.handleUpdateSelectGift(data);
    },
    handleSendGift() {
      this.visible = false;
      if (this.stoneNumber < this.selectedGift.price) {
        this.$router.push('/diamonds?tip=1');
        return;
      }
      if (this.loading) {
        showToast({
          message: this.t('global.c-gift-popup#发送礼物中')
        });
        return;
      }

      // 发送礼物
      this.loading = true;
      handleSendGiftAjax({
        anchorStringId: this.targetUserInfo.userStringId,
        giftId: this.selectedGift.id,
        sendScene: 0
      }).then(result => {
        if (result.code !== 0) {
          this.loading = false;
          return;
        }
        const payload = {
          type: ImMsgType.GIFT,
          data: {
            giftUrl: this.selectedGift.image,
            giftId: this.selectedGift.id,
            giftName: this.selectedGift.name,
            giftPrice: this.selectedGift.price,
            giftDesc: this.selectedGift.desc,
            integral: this.selectedGift.score,
            anchorGiftName: this.selectedGift.name,
            anchorGiftDesc: this.selectedGift.desc,
            dynamicEffects: this.selectedGift.dynamicEffects
          }
        };
        this.handleFetchStoneNumber();
        this.imInstance[this.isLiveScene ? 'handleSendLiveCustomMessage' : 'handleSendCustomMessage']('p2p', this.targetUserInfo.imId, payload, {
          vip: this.isVip ? '1' : '2'
        }).then(() => {
          if (!this.isLiveScene) {
            showToast({
              message: this.t('global.c-gift-popup#你发送了一件礼物')
            });
          }
          this.visible = false;
          this.loading = false;
        });
      });
    }
  }
});