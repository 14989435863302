import { defineComponent, ref, watchEffect } from 'vue';
export default defineComponent({
  name: 'custom-popup',
  emits: ['update:modelValue', 'ok', 'cancel'],
  props: ['modelValue', 'buttons'],
  setup(props, _ref) {
    let {
      emit
    } = _ref;
    const visiable = ref(props.modelValue);
    watchEffect(() => {
      visiable.value = props.modelValue;
    });
    watchEffect(() => emit('update:modelValue', visiable.value));
    return {
      visiable
    };
  },
  methods: {
    handleClickButton(item) {
      this.$emit(item.type);
    }
  }
});