import { defineComponent, ref, reactive } from 'vue';
import { DeductionRuleEvent } from '@/providers/deduction';
import { useDispatchVideoEventListener } from '../hooks/useDispatchVideoEventListener';
import CallLike from './call-like.vue';
import ScaleView from './scale-view.vue';
import CallReadly from './call-readly.vue';
import CallToolBar from './call-tool-bar.vue';
import CallNavigation from './call-navigation.vue';
import Bootstrapt from '@/providers/bootstrap';
import CallCountdownAnimation from './call-countdown-animation.vue';
import TimingTool from '@/components/timing-tool/index.vue';
import CallChatWindow from './call-chat-window.vue';
import PayPopup from '@/components/pay-popup/index.vue';
export default defineComponent({
  name: 'rtc-video-entry',
  setup() {
    const [isSmallest, isMin, countdown, payVisible, payGoods] = [ref(false), ref(false), ref(0), ref(false), reactive({})];
    const {
      imInstance,
      visible,
      status,
      targetUserInfo
    } = useDispatchVideoEventListener();
    return {
      isSmallest,
      isMin,
      countdown,
      imInstance,
      visible,
      status,
      targetUserInfo,
      payVisible,
      payGoods
    };
  },
  components: {
    'call-like': CallLike,
    'scale-view': ScaleView,
    'call-readly': CallReadly,
    'call-tool-bar': CallToolBar,
    'call-navigation': CallNavigation,
    'call-countdown-animation': CallCountdownAnimation,
    'timing-tool': TimingTool,
    'call-chat-window': CallChatWindow,
    'pay-popup': PayPopup
  },
  mounted() {
    Bootstrapt.eventer.on(DeductionRuleEvent.COUNTDOWN_UPDATE, number => {
      this.countdown = number;
    });
  },
  watch: {
    status(newValue) {
      if (newValue === '') {
        this.isMin = false;
        this.payVisible = false;
        this.isSmallest = false;
      }
    }
  },
  methods: {
    handleTriggerSmallVideoView(isSmallest) {
      this.isSmallest = isSmallest;
    },
    handleTriggerMinVideoView(isMin) {
      this.isMin = isMin;
    },
    handleCancelInvite() {
      console.log('handleCancelInvite');
      this.imInstance.handleCancelInvite().then(() => this.handleTriggerSmallVideoView(false));
    },
    handleRejectInvited() {
      console.log('handleRejectInvited');
      this.imInstance.handleRejectInvite();
    },
    handleAcceptInvited() {
      this.imInstance.handleReviceInvitedAndJoin().catch(e => {
        console.log('handleAcceptInvited', e);
      });
    },
    handleHangUpCall() {
      this.imInstance.handleHangUpChannel().catch(error => {
        console.log('event: ', error);
      });
    },
    handleInvokePay(goods) {
      this.payGoods = goods;
      this.payVisible = true;
    }
  }
});