import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: "pay-popup-btn",
    onClick: _cache[0] || (_cache[0] = function () {
      return _ctx.handleToPay && _ctx.handleToPay(...arguments);
    })
  }, _toDisplayString(_ctx.t('global.c-pay-popup#确认支付', {
    priceText: _ctx.priceText
  })), 1);
}