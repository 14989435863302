import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-396c475c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "call-navigation"
};
const _hoisted_2 = {
  class: "cn-title"
};
const _hoisted_3 = {
  class: "cn-title-user"
};
const _hoisted_4 = {
  class: "cn-title-img"
};
const _hoisted_5 = {
  class: "cn-title-infos"
};
const _hoisted_6 = {
  class: "cn-title-name"
};
const _hoisted_7 = {
  class: "cn-title-country"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_image = _resolveComponent("van-image");
  const _component_common_navigation = _resolveComponent("common-navigation");
  const _component_report_block_popup = _resolveComponent("report-block-popup");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_common_navigation, {
    clickBack: _ctx.handleHangUpCall
  }, {
    title: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_van_image, {
      width: "100%",
      height: "100%",
      src: _ctx.targetUserInfo.smallIcon,
      fit: "cover",
      round: true
    }, null, 8, ["src"])]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.targetUserInfo.nickname), 1), _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.targetUserInfo.country), 1)])]), !_ctx.isFollowed ? (_openBlock(), _createElementBlock("div", {
      key: 0,
      class: _normalizeClass(['cn-title-follower', _ctx.isFollowed ? 'selected' : '']),
      onClick: _cache[0] || (_cache[0] = $event => _ctx.handleTriggerFollow(_ctx.isFollowed))
    }, _toDisplayString(_ctx.isFollowed ? _ctx.$t('global.c-av-rtc-group#已关注') : _ctx.$t('global.c-av-rtc-group#关注')), 3)) : _createCommentVNode("", true)])]),
    right: _withCtx(() => [_createElementVNode("div", {
      class: "cn-right",
      onClick: _cache[1] || (_cache[1] = $event => _ctx.visible = true)
    })]),
    _: 1
  }, 8, ["clickBack"]), _createVNode(_component_report_block_popup, {
    modelValue: _ctx.visible,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.visible = $event),
    hasBlock: false,
    targetUserInfo: _ctx.targetUserInfo
  }, null, 8, ["modelValue", "targetUserInfo"])]);
}