import { ref, reactive, onMounted } from 'vue';
import { useImInstance } from '@/hooks/useImInstance';
import { getUserInfoFromStorage } from '@/utils/utils';
import { handleBatchGetUserInfosByYXIMIds } from '@/services/social';

const { YimDispatchEvent } = window.KKYIM;

export function useDispatchVideoEventListener() {
    const [status, visible] = [ref(''), ref(false)];
    const [userInfo, targetUserInfo] = [getUserInfoFromStorage(), reactive({})];

    const { imInstance } = useImInstance();

    // 请求用户信息
    const handleGetTargetUserInfo = (imId) => handleBatchGetUserInfosByYXIMIds({ imIds: [imId], callType: 0 }).then((result) => {
        if (result.code !== 0) {
            return;
        }
        Object.assign(targetUserInfo, result.data[0]);
    });

    // 监听主动调起视频
    const handleRoposeCallListener = () => {
        const { toAccid, fromAccid, attachExt } = imInstance.getInvitedInfos();

        handleGetTargetUserInfo(fromAccid === userInfo.imId ? toAccid : fromAccid).then(() => {
            if (attachExt && attachExt.myCallCustomInfo && attachExt.myCallCustomInfo.isMatchCall) {
                return;
            }
            status.value = 'initiate';
            visible.value = true;
        });
    };

    // 监听接收到邀请
    const handleReciveInvitedListener = () => {
        const { toAccid, fromAccid, attachExt } = imInstance.getInvitedInfos();

        handleGetTargetUserInfo(fromAccid === userInfo.imId ? toAccid : fromAccid).then(() => {
            if (attachExt && attachExt.myCallCustomInfo && attachExt.myCallCustomInfo.isMatchCall) {
                return;
            }
            status.value = 'invited';
            visible.value = true;
        });
    };

    // 监听双方进入 rtc 频道
    const handleEntryCallListener = () => {
        status.value = 'calling';
        visible.value = true;

        console.log('监听双方进入 rtc 频道');

        imInstance.handleSwitchTargetAccount('p2p', targetUserInfo.imId);
    };

    // 监听主动拒绝邀请
    const handleEndCallListener = () => {
        status.value = '';
        visible.value = false;
    };

    // 派发事件监听
    const handleDispatchEventListener = () => {
        // 主动调起语音
        imInstance.handleListenYimEvents(YimDispatchEvent.XL_PROPOSE_VIDEO_CALL, () => handleRoposeCallListener());

        // 接收到邀请
        imInstance.handleListenYimEvents(YimDispatchEvent.XL_RECIVE_VIDEO_INVITED, () => handleReciveInvitedListener());

        // 双方进入 rtc 频道
        imInstance.handleListenYimEvents(YimDispatchEvent.XL_ENTRY_VIDEO_CALL, () => handleEntryCallListener());

        // 主动拒绝邀请
        imInstance.handleListenYimEvents(YimDispatchEvent.XL_END_VIDEO_CALL, () => handleEndCallListener());
    };

    onMounted(() => {
        handleDispatchEventListener();
    });

    return {
        imInstance, status, visible, targetUserInfo,
    };
}
