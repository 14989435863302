import { ref, onMounted, onActivated } from 'vue';
import { handleGetStoneNumberAjax } from '@/services/diamonds';

export function useDiamonds() {
    const stoneNumber = ref(0);

    const handleFetchStoneNumber = () => handleGetStoneNumberAjax().then((result) => {
        stoneNumber.value = result.code === 0 ? result.data.stone : 0;
    });

    onMounted(() => handleFetchStoneNumber());

    onActivated(() => handleFetchStoneNumber());

    return {
        stoneNumber, handleFetchStoneNumber,
    };
}
