import { defineComponent, ref, watchEffect } from 'vue';
import { handleFollowAjax, handleCancelFollowAjax } from '@/services/relation';
import Navigation from '@/components/navigation/index.vue';
import ReportBlockPopup from '@/components/report-block-popup/index.vue';
export default defineComponent({
  name: 'call-navigation',
  props: ['targetUserInfo'],
  setup(props) {
    var _props$targetUserInfo;
    const [visible, isFollowed] = [ref(false), ref((_props$targetUserInfo = props.targetUserInfo) === null || _props$targetUserInfo === void 0 ? void 0 : _props$targetUserInfo.followed)];
    watchEffect(() => {
      var _props$targetUserInfo2;
      isFollowed.value = (_props$targetUserInfo2 = props.targetUserInfo) === null || _props$targetUserInfo2 === void 0 ? void 0 : _props$targetUserInfo2.followed;
    });
    return {
      visible,
      isFollowed
    };
  },
  components: {
    'common-navigation': Navigation,
    'report-block-popup': ReportBlockPopup
  },
  methods: {
    handleHangUpCall() {
      this.$emit('onHangUpCall');
    },
    handleTriggerFollow(isFollowed) {
      var _this$targetUserInfo;
      if (!((_this$targetUserInfo = this.targetUserInfo) !== null && _this$targetUserInfo !== void 0 && _this$targetUserInfo.userStringId)) {
        return Promise.resolve(false);
      }
      return (isFollowed ? handleCancelFollowAjax : handleFollowAjax)({
        userIdHash: this.targetUserInfo.userStringId
      }).then(result => {
        if (result.code !== 0) {
          return;
        }
        this.isFollowed = !isFollowed;
      });
    }
  }
});