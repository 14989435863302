import { ref, computed } from 'vue';
import Bootstrapt from '@/providers/bootstrap';

const { YimDispatchEvent } = window.KKYIM;

export function useImInstance() {
    const [messages, liveMessages, sessions, imInstance] = [ref([].concat(Bootstrapt.imInstance.messages)), ref([].concat(Bootstrapt.imInstance.liveMessages)), ref([].concat(Bootstrapt.imInstance.sessions)), Bootstrapt.imInstance];

    const messagesWithTimes = computed(() => {
        // eslint-disable-next-line prefer-const
        let [lastTime, result] = [0, []];

        if (messages.value.length === 0) {
            return result;
        }

        messages.value.forEach((messageItem) => {
            if (result.length === 0 || messageItem.getMessagePayload().time - lastTime > 1000 * 60 * 10) {
                lastTime = messageItem.getMessagePayload().time;
                result.push({ type: 'time-label', value: messageItem.getMessagePayload().time });
            }
            result.push(messageItem);
        });
        return result;
    });

    // 事件监听 - 消息
    imInstance.handleListenYimEvents(YimDispatchEvent.YIM_UPDATE_MESSAGES, (msgs) => {
        messages.value = [].concat(msgs);
    });

    // 事件监听 - 直播消息
    imInstance.handleListenYimEvents(YimDispatchEvent.YIM_UPDATE_LIVE_MESSAGES, (liveMsgs) => {
        liveMessages.value = [].concat(liveMsgs);
    });

    // 事件监听 - 会话
    imInstance.handleListenYimEvents(YimDispatchEvent.YIM_UPDATE_SESSIONS, (sess) => {
        sessions.value = [].concat(sess);
    });

    return {
        imInstance, messages, sessions, liveMessages, messagesWithTimes,
    };
}
