import { makeI18nT } from 'languages/index';
import { defineComponent } from 'vue';
import { IMG_RESOURCE_DOMAIN } from '@/utils/consts';
export default defineComponent({
  name: 'gift-footer',
  props: ['stoneNumber'],
  setup() {
    const {
      t
    } = makeI18nT();
    return {
      IMG_RESOURCE_DOMAIN,
      t
    };
  },
  methods: {
    handleSendGift() {
      this.$emit('onSendGift');
    }
  }
});