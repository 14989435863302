import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7c0b0072"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "alert-modal-wrap"
};
const _hoisted_2 = {
  class: "alert-modal"
};
const _hoisted_3 = {
  class: "am-close"
};
const _hoisted_4 = {
  class: "am-content"
};
const _hoisted_5 = {
  class: "am-content-message"
};
const _hoisted_6 = {
  class: "am-footer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_icon = _resolveComponent("van-icon");
  const _component_van_overlay = _resolveComponent("van-overlay");
  return _openBlock(), _createBlock(_component_van_overlay, {
    show: _ctx.visiable,
    customStyle: {
      zIndex: 120
    }
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_van_icon, {
      name: "close",
      onClick: _withModifiers(_ctx.handleCloseModal, ["stop"])
    }, null, 8, ["onClick"])]), _createElementVNode("div", _hoisted_4, [_renderSlot(_ctx.$slots, "default", {}, () => [_createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.message || this.t('glbal.c-custom-modal#默认消息')), 1)], true)]), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", {
      class: "am-footer-button",
      onClick: _cache[0] || (_cache[0] = _withModifiers(function () {
        return _ctx.handleOk && _ctx.handleOk(...arguments);
      }, ["stop"]))
    }, _toDisplayString(_ctx.buttonText || this.t('global.c-custom-modal#默认确定按钮')), 1)])])])]),
    _: 3
  }, 8, ["show"]);
}