import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-556d22ba"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "chat-record-item-wrap"
};
const _hoisted_2 = {
  class: "chat-record-item-flex"
};
const _hoisted_3 = {
  class: "chat-record-item-raw"
};
const _hoisted_4 = {
  key: 0
};
const _hoisted_5 = ["src"];
const _hoisted_6 = {
  key: 0,
  class: "chat-record-item-transtion"
};
const _hoisted_7 = {
  key: 0,
  class: "chat-record-item-transtion-icon"
};
const _hoisted_8 = {
  key: 0,
  class: "chat-record-item-violation"
};
const _hoisted_9 = {
  class: "chat-record-item-violation-text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _ctx$dataContent$data, _ctx$dataContent$data2;
  const _component_van_loading = _resolveComponent("van-loading");
  const _component_svg_iconfont = _resolveComponent("svg-iconfont");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
    class: _normalizeClass(['chat-record-item', _ctx.violationText ? '' : 'radius', _ctx.data.from === _ctx.userInfo.imId ? 'focus' : ''])
  }, [_createElementVNode("div", _hoisted_3, [_ctx.msgItem.type === _ctx.ImMsgType.TEXT ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.data.text), 1)) : _createCommentVNode("", true), _ctx.msgItem.type === _ctx.ImMsgType.GIFT ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 1
  }, [_createElementVNode("img", {
    src: (_ctx$dataContent$data = _ctx.dataContent.data) === null || _ctx$dataContent$data === void 0 ? void 0 : _ctx$dataContent$data.giftUrl,
    alt: ""
  }, null, 8, _hoisted_5), _createElementVNode("span", null, _toDisplayString((_ctx$dataContent$data2 = _ctx.dataContent.data) === null || _ctx$dataContent$data2 === void 0 ? void 0 : _ctx$dataContent$data2.giftDesc), 1)], 64)) : _createCommentVNode("", true)]), _ctx.translateText && !_ctx.isMockLiveNotice && _ctx.msgItem.type === _ctx.ImMsgType.TEXT ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("span", null, _toDisplayString(_ctx.translateText), 1)])) : _createCommentVNode("", true)], 2), _ctx.data.from !== _ctx.userInfo.imId && !_ctx.isMockLiveNotice && _ctx.msgItem.type === _ctx.ImMsgType.TEXT ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_ctx.loading ? (_openBlock(), _createBlock(_component_van_loading, {
    key: 0,
    type: "spinner",
    class: "translate-loading",
    size: "14",
    color: "#ffffff"
  })) : (_openBlock(), _createBlock(_component_svg_iconfont, {
    key: 1,
    class: "translate-icon",
    icon: "translate",
    onClick: _cache[0] || (_cache[0] = $event => _ctx.handleTranslateText(_ctx.data))
  }))])) : _createCommentVNode("", true)]), _ctx.violationText && !_ctx.isMockLiveNotice ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createVNode(_component_svg_iconfont, {
    class: "chat-record-item-violation-icon",
    icon: "warn-tip"
  }), _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.violationText), 1)])) : _createCommentVNode("", true)]);
}