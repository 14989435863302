import { createI18n } from 'vue-i18n';
import { getSystemLanguage } from 'packages/kk-tools-v1.0.0/dist/esm/index';

const languageMessagesMap = {};

function generateLanguageMessages() {
    const context = require.context('./', true, /\.js$/);
    const languagesPaths = context.keys().filter((item) => item !== './index.js');

    for (let i = 0, len = languagesPaths.length; i < len; i++) {
        const paths = languagesPaths[i].split(/[\/|\.]/);

        if (typeof languageMessagesMap[paths[2].split('-')[0]] === 'undefined') {
            languageMessagesMap[paths[2].split('-')[0]] = {};
        }
        languageMessagesMap[paths[2].split('-')[0]][paths[3]] = require(`languages/${paths[2]}/${paths[3]}.js`);
    }
}

let instance = null;

export function makeI18nT() {
    if (instance) {
        return instance.global;
    }

    if (Object.keys(languageMessagesMap).length === 0) {
        generateLanguageMessages();
    }

    // const language = getSystemLanguage() === 'zh-CN' ? 'en-US' : getSystemLanguage();

    instance = createI18n({ legacy: false, locale: 'en', messages: languageMessagesMap });

    return instance.global;
}
