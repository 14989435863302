import { makeI18nT } from 'languages/index';
import { getTouristUniqueDeviceId } from '@/providers/tourist';
import { showToast, showLoadingToast, allowMultipleToast } from 'vant';
import { requestAjax, forceJumpLink, getSystemLanguage, getSystemPlatformType } from 'packages/kk-tools-v1.0.0/dist/esm/index';

import * as consts from '@/utils/consts';

const { t } = makeI18nT();

allowMultipleToast();

function handleResponseWithFail(error, silence) {
    if (!silence) {
        showToast({ message: error.message, wordBreak: 'break-word' });
    }
    return Promise.reject(error);
}

function handleResponseWithSuccess(response, silence) {
    // 未登录
    if (response.code === 1 && window.location.pathname !== '/login') {
        window.localStorage.removeItem(consts.AUTHORIZATION);
        window.localStorage.removeItem(consts.IM_AUTHORIZATION);

        return forceJumpLink('/login', 'history') || {};
    }
    // 账号被封禁 -> 禁用
    if (response.code === 47) {
        return {};
    }
    // 异常
    if (response.code !== 0 && !silence) {
        showToast({ message: response.msg, wordBreak: 'break-word', position: 'bottom' });
    }
    return response || {};
}

let fetchLoading = null;

function handleGetXUserAgent() {
    const platformType = getSystemPlatformType();

    // eslint-disable-next-line no-nested-ternary
    const platformTypeText = platformType === 'android' ? 'Android' : (platformType === 'ios' ? 'IOS' : 'PC');

    return `${process.env.VUE_APP_AGENT} (${platformTypeText} 1.0.0)`;
}

function makeTimeoutPromise(timeout) {
    return new Promise((resolve) => {
        setTimeout(() => resolve({ code: -1, message: 'request timeout' }), timeout * 1000);
    });
}

export function getRequestHeaders() {
    return {
        'Content-Type': 'application/json',
        'x-user-agent': handleGetXUserAgent(),
        'x-device-id': getTouristUniqueDeviceId(),
        lang: getSystemLanguage(),
        authorization: window.localStorage.getItem(consts.AUTHORIZATION) || '',
    };
}

export function request(method, url, data, appendHeaders = {}, silence = false, abortController = null, timeout = 70) {
    const headers = {
        ...getRequestHeaders(), ...appendHeaders,
    };

    if (headers['Content-Type'] === 'multipart/form-data' || data instanceof FormData) {
        delete headers['Content-Type'];
    }

    if (!fetchLoading && !silence) {
        fetchLoading = showLoadingToast({ duration: 0, forbidClick: true, message: t('global.c-global#加载文案') });
    }

    const realUrl = /^\/google/.test(url) ? url : process.env.VUE_APP_API_DOMAIN + url;

    const fetchAjax = requestAjax(method, realUrl, data, headers, abortController)
        .then((response) => {
            fetchLoading = fetchLoading ? (fetchLoading.close() && null) : null;
            return handleResponseWithSuccess(response, silence);
        })
        .catch((error) => {
            fetchLoading = fetchLoading ? (fetchLoading.close() && null) : null;
            return handleResponseWithFail(error, silence);
        });

    return timeout > 0 ? Promise.race([fetchAjax, makeTimeoutPromise(timeout)]) : fetchAjax;
}
