import { defineComponent } from 'vue';
import { useBodyScroll } from '@/hooks/useBodyScroll';
export default defineComponent({
  name: 'common-navigation',
  props: {
    title: {
      type: String,
      default: ''
    },
    hasBack: {
      type: Boolean,
      default: true
    },
    clickBack: {
      type: Function,
      default: undefined
    },
    useGradient: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    const {
      scrollTop
    } = useBodyScroll();
    return {
      scrollTop
    };
  },
  computed: {
    bgClassName() {
      if (!this.useGradient) {
        return ['common-navigation', 'has-background'];
      }
      return this.scrollTop > 44 ? ['common-navigation', 'gradient-background'] : ['common-navigation'];
    }
  },
  methods: {
    handleClickLeft(event) {
      const element = event.target || event.srcElement;
      if (element.className !== 'cn-left-icon') {
        return null;
      }
      return typeof this.clickBack === 'undefined' ? this.$router.back() : this.clickBack();
    }
  }
});