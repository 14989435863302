import { request } from '@/utils/network';

// 获取钻石数目
export function handleGetStoneNumberAjax() {
    return request('post', '/point/stone/get-number', {}, {}, true);
}

// 私照消费钻石
export function handleConsumeStoneByPrivatePhotoAjax(params) {
    return request('post', '/point/stone/consume-by-private-photo', params, {}, true);
}

// 通过消息消费钻石
export function handleConsumeStoneByMsgAjax(params) {
    return request('post', '/point/stone/consume-by-message', params);
}
