export const routes = [{
    path: '/', redirect: '/index',
}, {
    path: '/index',
    component: () => import('@/views/index/index.vue'),
}, {
    path: '/login',
    component: () => import('@/views/login/index.vue'),
}, {
    path: '/mobile-register',
    component: () => import('@/views/mobile-register/index.vue'),
}, {
    path: '/email-register',
    component: () => import('@/views/email-register/index.vue'),
}, {
    path: '/completion',
    component: () => import('@/views/completion/index.vue'),
}, {
    path: '/mine',
    component: () => import('@/views/mine/index.vue'),
}, {
    path: '/profile',
    component: () => import('@/views/profile/index.vue'),
}, {
    path: '/setting',
    component: () => import('@/views/setting/index.vue'),
}, {
    path: '/account-security',
    component: () => import('@/views/account-security/index.vue'),
}, {
    path: '/about-us',
    component: () => import('@/views/about-us/index.vue'),
}, {
    path: '/blocked-list',
    component: () => import('@/views/blocked-list/index.vue'),
}, {
    path: '/hot',
    meta: { keepAlive: true },
    component: () => import('@/views/hot/index.vue'),
}, {
    path: '/messages',
    meta: { keepAlive: true },
    component: () => import('@/views/messages/index.vue'),
}, {
    path: '/records',
    component: () => import('@/views/records/index.vue'),
}, {
    path: '/diamonds',
    component: () => import('@/views/diamonds/index.vue'),
}, {
    path: '/vip',
    component: () => import('@/views/vip/index.vue'),
}, {
    path: '/anchor',
    component: () => import('@/views/anchor/index.vue'),
}, {
    path: '/anchor-record',
    component: () => import('@/views/anchor-record/index.vue'),
}, {
    path: '/chat',
    component: () => import('@/views/chat/index.vue'),
}, {
    path: '/follower',
    component: () => import('@/views/follower/index.vue'),
}, {
    path: '/following',
    component: () => import('@/views/following/index.vue'),
}, {
    path: '/agreement',
    component: () => import('@/views/agreement/index.vue'),
}, {
    path: '/privacy-policy',
    component: () => import('@/views/privacy-policy/index.vue'),
}, {
    path: '/system-not',
    component: () => import('@/views/system-not/index.vue'),
}, {
    path: '/customer-service',
    component: () => import('@/views/customer-service/index.vue'),
}, {
    path: '/example',
    component: () => import('@/views/example/index.vue'),
}, {
    path: '/apple-redirect',
    component: () => import('@/views/apple-redirect/index.vue'),
}];
