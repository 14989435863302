import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-168afbf4"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "report-block-popup"
};
const _hoisted_2 = {
  class: "rbp-content"
};
const _hoisted_3 = {
  key: 0,
  class: "rbp-content-inner"
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = {
  key: 1,
  class: "rbp-content-inner"
};
const _hoisted_6 = {
  class: "rbp-content-title"
};
const _hoisted_7 = {
  class: "rbp-content-descrription"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_custom_popup = _resolveComponent("custom-popup");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_custom_popup, {
    modelValue: _ctx.visible,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.visible = $event),
    buttons: _ctx.type === '3' ? _ctx.buttons : false,
    onCancel: _ctx.handleCancel,
    onOk: _ctx.handleOk
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_ctx.type < 3 ? (_openBlock(), _createElementBlock("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.adaptedOpecations, item => {
      return _openBlock(), _createElementBlock("div", {
        class: "rbp-opecation-item",
        key: item.type,
        onClick: $event => _ctx.handleTiggerItem(_ctx.type, item.type)
      }, [_createElementVNode("span", null, _toDisplayString(item.text), 1)], 8, _hoisted_4);
    }), 128))])) : _createCommentVNode("", true), _ctx.type === '3' ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.t('global.c-report-block-popup#确认要拉黑XXX吗', {
      nickname: _ctx.targetUserInfo.nickname
    })), 1), _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.t('global.c-report-block-popup#如果拉黑，不能在看到对方的信息，确认要拉黑XXX吗', {
      heOrHer: _ctx.targetUserInfo.gender > 1 ? 'her' : 'him'
    })), 1)])) : _createCommentVNode("", true)])]),
    _: 1
  }, 8, ["modelValue", "buttons", "onCancel", "onOk"])]);
}