/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { request } from '@/utils/network';

// 获取 hot 列表
export function handlePopularListAjax(params) {
    return request('post', '/social/user/get-host-list', params);
}

// 获取主播信息
export function handleFetchAnchorInfoAjax(params) {
    return request('post', '/social/user/anchor-data-info', params);
}

// 获取视频信息
export function handleFetchOwnerVideosAjax(params) {
    return request('post', '/social/user/video/get-own-videos-v2', params);
}

// 获取其他用户视频信息
export function handleFetchUserVideosAjax(params) {
    return request('post', '/social/user/video/get-other-videos', params, {}, true);
}

// 上传用户视频
export function handleUploadOwnerVideoAjax(params) {
    const formData = new FormData();

    for (const prop in params) {
        formData.append(prop, params[prop]);
    }
    return request('post', '/social/user/video/upload-video', formData, { 'Content-Type': 'multipart/form-data' });
}

// 通过云信 imId 获取用户信息列表
export function handleBatchGetUserInfosByYXIMIds(params) {
    return request('post', '/social/user/batch-get-detail-imids', params);
}

// 通过 userStringId 获取用户信息
export function handleGetUserInfoByUserStringId(params) {
    return request('post', '/social/user/get-detail', params);
}

// google 翻译
export function handleInvokeGoogleTranslate(text) {
    return request('get', `${process.env.VUE_APP_GOOGLE_TRANSLATE_PREFIX}/translate_a/single?client=gtx&sl=auto&tl=en-US&dt=t&q=${window.encodeURIComponent(text)}`, {}, {}, true);
}

// 心跳
export function handleOnlineUpdateStateAjax() {
    return request('post', '/online/update-state', {}, {}, true);
}

// 客服
export function handleCSCNotifyAjax(params) {
    return request('post', '/social/csc/notify', params);
}

// 接通人工客服
export function handleConnectCSCAjax() {
    return request('post', '/social/csc/connect', {});
}

// 挂断人工客服
export function handleTerminateCSCAjax() {
    return request('post', '/social/csc/terminate', {});
}
