import { request } from '@/utils/network';

// 获取最后一条消息
export function handleGetLastSystemNoticeAjax(params) {
    return request('post', '/notification/get-latest-msg-v2', params);
}

// 系统消息列表
export function handleGetSystemNoticeAjax(params) {
    return request('post', '/notification/get-message-list-v2', params);
}

// 上报日志
export function handleKKReportLogAjax(params) {
    return request('post', '/event-tracking/report', params);
}

// 统计上报
export function handleKKStatisticsReportAjax(params) {
    return request('post', '/event-tracking/push-user-operate', params, {}, true);
}

// 进入页面统计上报
export function handleKKStatisticsReportWhenEntryAjax(params) {
    return request('post', '/event-tracking/push-user-click', params, {}, true);
}

// 匹配结果上报
export function handleMatchResultReportAjax(params) {
    return request('post', '/event-tracking/push-user-matching-operate', params, {}, true);
}

// 上报错误
export function handleReportErrorDetailAjax(params) {
    return request('post', '/event-tracking/report-error', params, {}, true);
}
