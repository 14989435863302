import { defineComponent, ref } from 'vue';
import { handleLikeAjax } from '@/services/relation';
import Bootstrapt from '../../../providers/bootstrap';
const {
  YimDispatchEvent
} = window.KKYIM;
export default defineComponent({
  name: 'call-like',
  props: ['targetUserInfo'],
  setup() {
    const [isLiked, likeText] = [ref(false), ref('')];
    return {
      isLiked,
      likeText
    };
  },
  mounted() {
    Bootstrapt.imInstance.handleListenYimEvents(YimDispatchEvent.SYS_NOTICE, this.handleSysNoticeListener);
  },
  unmounted() {
    Bootstrapt.imInstance.handleRemoveListenedYimEvents(YimDispatchEvent.SYS_NOTICE, this.handleSysNoticeListener);
  },
  methods: {
    handleSysNoticeListener(data) {
      if (data.id !== '1004') {
        return;
      }
      this.likeText = this.$t('global.c-av-rtc-group#喜欢来自', {
        nickname: data.data.fromNickname
      });
      setTimeout(() => {
        this.likeText = '';
      }, 2 * 1000);
    },
    handleLike() {
      if (this.isLiked) {
        return Promise.resolve(false);
      }
      return handleLikeAjax({
        userId: this.targetUserInfo.userId
      }).then(() => {
        this.isLiked = true;
      });
    }
  }
});