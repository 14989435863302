import { defineComponent } from 'vue';
import { handleTryParseJSON } from '@/utils/utils';
import { useImInstance } from '@/hooks/useImInstance';
import { showSvgaPlayerModal } from '@/components/custom-modal/index';
import CallChatRecord from './call-chat-record.vue';
const {
  ImMsgType
} = window.KKYIM;
export default defineComponent({
  name: 'call-chat-window',
  props: ['targetUserInfo'],
  setup() {
    const {
      imInstance,
      liveMessages
    } = useImInstance();
    return {
      imInstance,
      liveMessages
    };
  },
  components: {
    'call-chat-record': CallChatRecord
  },
  watch: {
    'lastMsgItem.idClient': {
      handler() {
        this.$nextTick(() => {
          const content = handleTryParseJSON(this.lastMsgItem ? this.lastMsgItem.content || null : null);
          if (content.type && content.type.toString() === ImMsgType.GIFT) {
            showSvgaPlayerModal({
              url: content.data.dynamicEffects
            }, 'svga-animation');
            return;
          }
          this.handleTriggerScrollAnimation()();
        });
      }
    }
  },
  computed: {
    lastMsgItem() {
      var _this$liveMessages;
      return (_this$liveMessages = this.liveMessages[this.liveMessages.length - 1]) === null || _this$liveMessages === void 0 ? void 0 : _this$liveMessages.getMessagePayload();
    },
    textLiveMessages() {
      return this.liveMessages.filter(item => item.type === ImMsgType.TEXT);
    }
  },
  methods: {
    handleTriggerScrollAnimation() {
      const {
        callChatWindow
      } = this.$refs;
      const {
        height
      } = callChatWindow.getBoundingClientRect();
      let handler = null;
      return () => {
        cancelAnimationFrame(handler);
        const animation = () => {
          callChatWindow.scrollTop += callChatWindow.scrollTop < callChatWindow.scrollHeight ? 50 : 0;
          if (Math.round(callChatWindow.scrollTop + height) < callChatWindow.scrollHeight - 5) {
            handler = requestAnimationFrame(animation);
          } else {
            cancelAnimationFrame(handler);
          }
        };
        requestAnimationFrame(animation);
      };
    }
  }
});