import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-11499024"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "custom-popup"
};
const _hoisted_2 = {
  class: "custom-popup-content"
};
const _hoisted_3 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_popup = _resolveComponent("van-popup");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_popup, {
    show: _ctx.visiable,
    "onUpdate:show": _cache[0] || (_cache[0] = $event => _ctx.visiable = $event),
    "safe-area-inset-bottom": "",
    "overlay-class": "mask-overlay",
    position: "bottom"
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "default", {}, undefined, true), _renderSlot(_ctx.$slots, "buttons", {}, () => [_ctx.buttons ? (_openBlock(), _createElementBlock("div", {
      key: 0,
      class: _normalizeClass(['custom-popup-button-wrap', _ctx.buttons.length > 1 ? '' : 'large'])
    }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buttons, item => {
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(['custom-popup-button', item.type]),
        onClick: $event => _ctx.handleClickButton(item),
        key: item.type
      }, _toDisplayString(item.text), 11, _hoisted_3);
    }), 128))], 2)) : _createCommentVNode("", true)], true)])]),
    _: 3
  }, 8, ["show"])]);
}