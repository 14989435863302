import { request } from '@/utils/network';

// 获取匹配记录
export function handFetchMatchedRecordsAjax(params) {
    return request('post', '/matching/v3/match-record', params);
}

// 获取匹配配置
export function handleFetchMatchSettingAjax(params) {
    return request('post', '/matching/v3/match-info', params);
}

// 设置匹配性别
export function handleSaveMatchGenderAjax(params) {
    return request('post', '/matching/v3/gender-preference', params);
}

// 是否允许匹配
export function handleIsAllowToMatchAjax(params) {
    return request('post', '/matching/v3/allow-match', params, {}, true);
}

// 退出匹配
export function handleExitMatchAjax() {
    return request('post', '/matching/v3/exit', {}, {}, true);
}

// 进入匹配
export function handleEntryMatchAjax(params, abortController) {
    return handleExitMatchAjax().then((result) => result.code === 0).then(() => request('post', '/matching/v3/enter', params, {}, true, abortController));
}

// 匹配开始信息
export function handleFetchStartMatchInfoAjax(params) {
    return request('post', '/matching/v3/match-start-info', params, {}, true);
}

// 匹配扣费
export function handleFetchMatchDeductionAjax(params) {
    return request('post', '/matching/v3/deduction', params, {}, true);
}
