import { makeI18nT } from 'languages/index';
import { defineComponent, ref, watchEffect } from 'vue';
import { noop } from 'packages/kk-tools-v1.0.0/dist/esm/index';
export default defineComponent({
  name: 'alert-modal',
  props: ['modelValue', 'message', 'buttonText'],
  emits: ['update:modelValue', 'ok'],
  setup(props, _ref) {
    let {
      emit
    } = _ref;
    const [visiable] = [ref(props.modelValue)];
    const {
      t
    } = makeI18nT();
    watchEffect(() => {
      visiable.value = props.modelValue;
    });
    watchEffect(() => emit('update:modelValue', visiable.value));
    return {
      visiable,
      noop,
      t
    };
  },
  methods: {
    handleCloseModal() {
      this.visiable = false;
    },
    handleOk() {
      this.visiable = false;
      this.$emit('ok');
    }
  }
});