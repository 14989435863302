import { defineComponent } from 'vue';
import { useImInstance } from '@/hooks/useImInstance';
import { handleInvokeGoogleTranslate } from '@/services/social';
import { getUserInfoFromStorage, handleTryParseJSON } from '@/utils/utils';
import SvgIconfont from '@/components/svg-iconfont/index.vue';
const {
  YimDispatchEvent,
  ImMsgType
} = window.KKYIM;
export default defineComponent({
  name: 'call-chat-record',
  props: ['msgItem', 'targetUserInfo'],
  data() {
    const userInfo = getUserInfoFromStorage();
    const {
      imInstance
    } = useImInstance();
    return {
      loading: false,
      userInfo,
      imInstance,
      translateText: '',
      violationText: '',
      ImMsgType
    };
  },
  components: {
    'svg-iconfont': SvgIconfont
  },
  computed: {
    data() {
      return this.msgItem.getMessagePayload();
    },
    dataContent() {
      return handleTryParseJSON(this.data.content || null);
    },
    isMockLiveNotice() {
      return /^\$cc/.test(this.data.idClient);
    }
  },
  mounted() {
    this.handleMsgViolationListener();
  },
  methods: {
    handleMsgViolationListener() {
      this.imInstance.handleListenYimEvents(YimDispatchEvent.YIM_UPDATE_LIVE_MESSAGES, () => this.handleUpdateViolationText());
    },
    handleUpdateViolationText() {
      if (!this.msgItem.isNotAllowed() || this.msgItem.type !== ImMsgType.TEXT) {
        return;
      }
      this.msgItem.getNotAllowedViolationInfo().then(result => {
        this.violationText = result;
      });
    },
    handleTranslateText(data) {
      if (this.translateText) {
        return;
      }
      this.loading = true;
      handleInvokeGoogleTranslate(data.text).then(result => {
        this.loading = false;
        this.translateText = result[0][0][0] || '';
      });
    }
  }
});