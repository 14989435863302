import { ref, onMounted, onUnmounted } from 'vue';

export function useBodyScroll() {
    const scrollTop = ref(0);

    const handleUpdateScrollTop = () => {
        scrollTop.value = document.documentElement.scrollTop;
    };

    onMounted(() => {
        window.addEventListener('scroll', handleUpdateScrollTop, false);
    });

    onUnmounted(() => {
        window.removeEventListener('scroll', handleUpdateScrollTop, false);
    });

    return {
        scrollTop,
    };
}
