import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-43face14"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "ppi-left"
};
const _hoisted_2 = {
  class: "ppi-left-channel"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: "pay-popup-item",
    onClick: _cache[0] || (_cache[0] = function () {
      return _ctx.handleSelect && _ctx.handleSelect(...arguments);
    })
  }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", {
    class: "ppi-left-icon",
    style: _normalizeStyle({
      background: `url(${_ctx.data.icon}) no-repeat center/cover`
    })
  }, null, 4), _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.data.channel), 1)]), _createElementVNode("div", {
    class: _normalizeClass(['ppi-right', _ctx.selectedPayId === _ctx.data.id ? 'selected' : ''])
  }, null, 2)]);
}