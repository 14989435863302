import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view");
  const _component_rtc_video_entry = _resolveComponent("rtc-video-entry");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_router_view, null, {
    default: _withCtx(_ref => {
      let {
        Component
      } = _ref;
      return [(_openBlock(), _createBlock(_KeepAlive, null, [_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
        key: _ctx.$route.fullPath
      })) : _createCommentVNode("", true)], 1024)), !_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
        key: _ctx.$route.fullPath
      })) : _createCommentVNode("", true)];
    }),
    _: 1
  }), _ctx.isShowVideoLayer ? (_openBlock(), _createBlock(_component_rtc_video_entry, {
    key: 0,
    ref: "RTCVideoRef"
  }, null, 512)) : _createCommentVNode("", true)]);
}