import { ref, onMounted, computed } from 'vue';
import { handleFetchPayWayAjax } from '@/services/point';

export function usePayTypes() {
    const payTypes = ref([]);
    const selectedPayId = ref('');

    const handleFetchPayTypes = () => {
        handleFetchPayWayAjax().then((result) => {
            if (result.code !== 0) {
                return;
            }
            payTypes.value = result.data;
            selectedPayId.value = result.data[0]?.id;
        });
    };

    const handleUpdateSelectPayType = (id) => {
        selectedPayId.value = id;
    };

    const selectedPayItem = computed(() => payTypes.value.find((item) => item.id === selectedPayId.value));

    onMounted(() => handleFetchPayTypes());

    return {
        payTypes, selectedPayId, selectedPayItem, handleUpdateSelectPayType,
    };
}
