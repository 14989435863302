import Bootstrapt from '@/providers/bootstrap';

// 页面跳转、视频挂断
export function handleVideoSmallViewWhenJumpGuard(to, from) {
    if (!Bootstrapt.imInstance || !Bootstrapt.imInstance.getInvitedInfos().channelInfo?.channelId) {
        return;
    }
    if (to.path === '/diamonds') {
        Bootstrapt.videoControlRef?.handleTriggerMinVideoView(true);
        return;
    }
    if (from.path === '/diamonds') {
        Bootstrapt.videoControlRef?.handleTriggerMinVideoView(false);
        return;
    }
    Bootstrapt.videoControlRef?.handleHangUpCall();
}
