import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-b4bff1d0"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "call-tool-bar"
};
const _hoisted_2 = {
  class: "ctb-inner"
};
const _hoisted_3 = {
  class: "ctb-input-wrap"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_field = _resolveComponent("van-field");
  const _component_gift_popup = _resolveComponent("gift-popup");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
    class: "ctb-gift-icon",
    onClick: _cache[0] || (_cache[0] = function () {
      return _ctx.handleTriggerGiftPopup && _ctx.handleTriggerGiftPopup(...arguments);
    })
  }), _createElementVNode("div", _hoisted_3, [_createVNode(_component_van_field, {
    ref: "inputRef",
    modelValue: _ctx.inputText,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.inputText = $event),
    placeholder: _ctx.t('global.c-av-rtc-group#请输入')
  }, null, 8, ["modelValue", "placeholder"])]), _createElementVNode("div", {
    class: "ctb-send-icon",
    onClick: _cache[2] || (_cache[2] = function () {
      return _ctx.handleSendMessage && _ctx.handleSendMessage(...arguments);
    })
  })]), _createVNode(_component_gift_popup, {
    modelValue: _ctx.giftVisible,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _ctx.giftVisible = $event),
    targetUserInfo: _ctx.targetUserInfo,
    isVip: true,
    isLiveScene: true
  }, null, 8, ["modelValue", "targetUserInfo"])]);
}