import { router } from '@/router/index';
import { MATCH_INTERRUUPT } from '@/utils/consts';
import { handleFetchMatchDeductionAjax } from '@/services/match';

import * as Comlink from 'comlink';
import Matcher from '@/providers/matcher';
import Bootstrapt from '@/providers/bootstrap';

export const DeductionRuleEvent = {
    SHOW_COUNTDOWN: 'SHOW_COUNTDOWN', COUNTDOWN_UPDATE: 'COUNTDOWN_UPDATE', HIDE_COUNTDOWN: 'HIDE_COUNTDOWN',
};

export default class DeductionRule {
    static instance = null;

    // eslint-disable-next-line no-return-assign
    static getInstance = () => DeductionRule.instance || (DeductionRule.instance = new DeductionRule());

    constructor() {
        this.options = {};
        this.countdown = 0;

        // 倒计时商品信息
        this.countdownTipInfo = null;

        // 能否转分钟通话
        this.isSwitchedMinCall = false;
    }

    // 接通音视频 -> 开始扣费
    async start(type, userId, attachExt) {
        const { isMatchCall = false } = attachExt.myCallCustomInfo || {};

        // 暂存参数
        Object.assign(this.options, { type, userId, attachExt });

        // 开始计时器
        this.handleStartTimeCountdown();

        console.log('是否为匹配通话:', isMatchCall);

        // 直接分钟通话
        if (!isMatchCall) {
            await this.handleNotMatchDeductionStart();
            return;
        }

        // 匹配通话
        await this.handleMatchDeductionStart();
    }

    // 开始计时器
    async handleStartTimeCountdown() {
        await Bootstrapt.webWorker.handleStartCountdownTimer(Comlink.proxy((number) => {
            this.countdown = number;

            // 发布定时器
            Bootstrapt.eventer.emit(DeductionRuleEvent.COUNTDOWN_UPDATE, number);

            // 发布倒计时信息
            if (this.countdownTipInfo && number >= this.countdownTipInfo.start) {
                Bootstrapt.eventer.emit(DeductionRuleEvent.SHOW_COUNTDOWN, this.countdownTipInfo);
                this.countdownTipInfo = null;
            }

            // 匹配转分钟通话
            if (number >= 25 && !this.isSwitchedMinCall) {
                this.handleNotMatchDeductionStart();
            }
        }));
    }

    // 分钟电话开始扣费
    async handleNotMatchDeductionStart() {
        const { type, userId, attachExt } = this.options;
        const { isMatchCall = false } = attachExt.myCallCustomInfo || {};

        const params = {
            type: type === 1 ? 'voiceChat' : 'videoChat', targetUserId: userId, channelId: attachExt?.channelName, match: isMatchCall,
        };
        if (isMatchCall) {
            params.matchType = 'matching';
        }

        this.isSwitchedMinCall = true;

        await Bootstrapt.webWorker.handleStartBilling(params, Comlink.proxy(this.handleStartBillingCallBack.bind(this)));
    }

    // 分钟通话响应
    handleStartBillingCallBack(result) {
        const targetUserInfo = Matcher.getMatchInstance().getTargetUserInfo();
        const { isMatchCall = false } = this.options.attachExt.myCallCustomInfo || {};

        // 自定义的超时
        if (result.code === -1) {
            return Bootstrapt.imInstance.handleHangUpChannel();
        }
        // 本次钻石不足
        if (result.code === 9) {
            return Bootstrapt.imInstance.handleHangUpChannel().then(() => {
                if (isMatchCall && this.countdown < 60) {
                    // 返回之后继续匹配
                    window.sessionStorage.setItem(MATCH_INTERRUUPT, '1');
                    Bootstrapt.eventer.emit(DeductionRuleEvent.HIDE_COUNTDOWN, { type: 'match', targetUserInfo });
                    return;
                }
                router.push('/diamonds?tip=1');
            });
        }
        // 下次钻石不足 - 展示倒计时，30s 之后挂断
        if (result.code === 0 && !result.data.nextEnough) {
            this.countdownTipInfo = { type: 'min', goods: result.data.callShowGoods, duration: 30, start: this.countdown + 30 };
        }
        return null;
    }

    // 匹配通话
    async handleMatchDeductionStart() {
        const { attachExt } = this.options;

        const [targetUserInfo, startMatchPayload] = [Matcher.getMatchInstance().getTargetUserInfo(), Matcher.getMatchInstance().getStartMatchPayload()];

        // 接通之后 -> 匹配扣费
        console.warn('匹配扣费');
        const result = await handleFetchMatchDeductionAjax({ gender: startMatchPayload?.gender, targetUserId: targetUserInfo.userId, channelName: attachExt?.channelName });

        if (result.code !== 0) {
            return;
        }

        // 不够转分钟通话 - 倒计时提示
        if (!result.data.nextEnough) {
            this.countdownTipInfo = { type: 'match', goods: result.data.callShowGoods, duration: 20, start: this.countdown + 5 };
        }
    }

    async stop() {
        this.isSwitchedMinCall = false;

        await Bootstrapt.webWorker.handleStopBilling();
        await this.handleStopTimeCountdown();
    }

    async handleStopTimeCountdown() {
        await Bootstrapt.webWorker.handleStopCountdownTimer();
        this.countdown = 0;
    }
}
