import { makeI18nT } from 'languages/index';
import { Vue3Lottie } from 'vue3-lottie';
import { IMG_RESOURCE_DOMAIN } from '@/utils/consts';
import { defineComponent, ref, reactive } from 'vue';
import { DeductionRuleEvent } from '@/providers/deduction';
import Bootstrapt from '@/providers/bootstrap';
const {
  YimDispatchEvent
} = window.KKYIM;
export default defineComponent({
  name: 'call-countdown-animation',
  emits: ['onInvokePay', 'onHangUpCall'],
  props: ['targetUserInfo', 'countdown'],
  setup() {
    const [visible, isMatchCall, type, goods, limit, animationLink] = [ref(false), ref(false), ref(false), ref(''), reactive({}, ref(0)), ref('')];
    const {
      t
    } = makeI18nT();
    return {
      visible,
      isMatchCall,
      type,
      goods,
      limit,
      animationLink,
      t,
      IMG_RESOURCE_DOMAIN
    };
  },
  components: {
    'vue3-lottie': Vue3Lottie
  },
  mounted() {
    this.handleEventListener();
  },
  unmounted() {
    Bootstrapt.eventer.removeListener(DeductionRuleEvent.SHOW_COUNTDOWN, this.handleShowCountdown);
  },
  watch: {
    countdown(newValue, oldValue) {
      if (newValue >= this.limit && this.visible) {
        if (this.$refs.countdownLottie) {
          this.$refs.countdownLottie.stop();
        }
        this.visible = false;
      }
      if (newValue <= 0 && oldValue > 0) {
        this.visible = false;
      }
    }
  },
  methods: {
    handleEventListener() {
      Bootstrapt.eventer.on(DeductionRuleEvent.SHOW_COUNTDOWN, this.handleShowCountdown);
      Bootstrapt.imInstance.handleListenYimEvents(YimDispatchEvent.XL_ENTRY_VIDEO_CALL, () => {
        var _attachExt$myCallCust;
        const {
          attachExt
        } = Bootstrapt.imInstance.getInvitedInfos();
        this.isMatchCall = attachExt === null || attachExt === void 0 || (_attachExt$myCallCust = attachExt.myCallCustomInfo) === null || _attachExt$myCallCust === void 0 ? void 0 : _attachExt$myCallCust.isMatchCall;
      });
    },
    handleShowCountdown(countdownTipInfo) {
      this.visible = true;
      this.type = countdownTipInfo.type || '';
      this.goods = countdownTipInfo.goods || {};
      this.limit = countdownTipInfo.start + countdownTipInfo.duration;
      this.$nextTick(() => {
        if (this.countdown === countdownTipInfo.start) {
          this.animationLink = `/animations/chat-countdown-${countdownTipInfo.type === 'match' ? '20' : '30'}/data.json`;
        }
      });
    },
    handleInvokePay() {
      this.$emit('onInvokePay', this.goods);
    }
  }
});