import { request } from '@/utils/network';

// 关注粉丝数目
export function handleFetchRelationNumberAjax() {
    return request('post', '/relation/number', {});
}

// 关注列表
export function handleFetchRelationListAjax(params) {
    return request('post', '/relation/list', params);
}

// 点赞
export function handleLikeAjax(params) {
    return request('post', '/relation/like', params);
}

// 关注
export function handleFollowAjax(params) {
    return request('post', '/relation/follow', params);
}

// 取消关注
export function handleCancelFollowAjax(params) {
    return request('post', '/relation/cancel-follow', params);
}
