import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-31dd344c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "pay-popup-wrap"
};
const _hoisted_2 = {
  class: "pay-popup"
};
const _hoisted_3 = {
  class: "pay-popup-content"
};
const _hoisted_4 = {
  class: "pay-popup-footer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_pay_item = _resolveComponent("pay-item");
  const _component_pay_btn = _resolveComponent("pay-btn");
  const _component_van_popup = _resolveComponent("van-popup");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_popup, {
    show: _ctx.visible,
    "onUpdate:show": _cache[0] || (_cache[0] = $event => _ctx.visible = $event),
    round: "",
    position: "bottom"
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.payTypes, (item, index) => {
      return _openBlock(), _createBlock(_component_pay_item, {
        key: index,
        data: item,
        selectedPayId: _ctx.selectedPayId,
        onSelect: _ctx.handleSelect
      }, null, 8, ["data", "selectedPayId", "onSelect"]);
    }), 128))]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_pay_btn, {
      priceText: _ctx.priceText,
      onPay: _ctx.handleToPay
    }, null, 8, ["priceText", "onPay"])])])]),
    _: 1
  }, 8, ["show"])]);
}