import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-47a2a24e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "target-user-info"
};
const _hoisted_2 = {
  class: "tui-avatar"
};
const _hoisted_3 = {
  class: "tui-name"
};
const _hoisted_4 = {
  class: "tui-country"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_image = _resolveComponent("van-image");
  const _component_svg_iconfont = _resolveComponent("svg-iconfont");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_van_image, {
    width: "2.52rem",
    height: "2.52rem",
    round: "",
    fit: "cover",
    src: _ctx.targetUserInfo.icon,
    alt: "avatar"
  }, null, 8, ["src"])]), _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.targetUserInfo.nickname) + " " + _toDisplayString(_ctx.targetUserInfo.age), 1), _createElementVNode("div", _hoisted_4, [_createVNode(_component_svg_iconfont, {
    icon: "location",
    class: "tui-country-icon"
  }), _createElementVNode("span", null, _toDisplayString(_ctx.targetUserInfo.country), 1)])]);
}