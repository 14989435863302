import { defineComponent } from 'vue';
export default defineComponent({
  name: 'timing-tool',
  props: ['countdown'],
  computed: {
    timeText() {
      const [minute, second] = [Math.floor(this.countdown / 60), this.countdown % 60];
      return `${Math.max(minute, 0)}:${second >= 10 ? second : `0${second}`}`;
    }
  }
});