import { defineComponent } from 'vue';
import CallOpecation from './call-opecation.vue';
import TargetUserInfo from './target-user-info.vue';
export default defineComponent({
  name: 'call-readly',
  props: ['status', 'targetUserInfo'],
  emits: ['onCancelInvite', 'onRejectInvited', 'onAcceptInvited'],
  components: {
    'call-opecation': CallOpecation,
    'target-user-info': TargetUserInfo
  },
  methods: {
    handleDispatchEmitEvent(event) {
      this.$emit(event);
    }
  }
});