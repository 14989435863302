import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5e416a60"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "call-opecation"
};
const _hoisted_2 = {
  class: "call-opecation-text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.statusText), 1), _ctx.status === 'initiate' ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: _normalizeClass(['call-opecation-content', _ctx.status])
  }, [_createElementVNode("div", {
    class: "call-opecation-item reject",
    onClick: _cache[0] || (_cache[0] = _withModifiers(function () {
      return _ctx.handleCancelInvite && _ctx.handleCancelInvite(...arguments);
    }, ["stop"]))
  })], 2)) : _createCommentVNode("", true), _ctx.status === 'invited' ? (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: _normalizeClass(['call-opecation-content', _ctx.status])
  }, [_createElementVNode("div", {
    class: "call-opecation-item reject",
    onClick: _cache[1] || (_cache[1] = _withModifiers(function () {
      return _ctx.handleRejectInvited && _ctx.handleRejectInvited(...arguments);
    }, ["stop"]))
  }), _createElementVNode("div", {
    class: "call-opecation-item resolve",
    onClick: _cache[2] || (_cache[2] = _withModifiers(function () {
      return _ctx.handleAcceptInvited && _ctx.handleAcceptInvited(...arguments);
    }, ["stop"]))
  })], 2)) : _createCommentVNode("", true)]);
}