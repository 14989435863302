import { createApp } from 'vue';
import { Lazyload, Locale } from 'vant';
import { router } from '@/router/index';
import { makeI18nT } from 'languages/index';
import { getSystemLanguage } from 'packages/kk-tools-v1.0.0/dist/esm/index';

import drag from 'v-drag';
import App from './App.vue';
import Bootstrap from '@/providers/bootstrap';
import Vue3TouchEvents from 'vue3-touch-events';

import EnUs from 'vant/es/locale/lang/en-US';
import './registerServiceWorker';

const app = createApp(App);

const locale = getSystemLanguage();

app.use(router).use(Lazyload).use(Vue3TouchEvents).use(drag);

app.config.globalProperties.$t = makeI18nT().t;

const libraryLanguage = {
    'en-US': EnUs, 'zh-CN': EnUs,
};

Locale.use(locale, libraryLanguage[locale]);

new Bootstrap().init().then((bootstrap) => app.provide('$bootstrap', bootstrap).mount('#app'));
