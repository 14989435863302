import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-484d2618"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "gift-swipe"
};
const _hoisted_2 = {
  class: "gift-content-panel"
};
const _hoisted_3 = {
  class: "gift-swiper-indicator"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_gift_item = _resolveComponent("gift-item");
  const _component_van_swipe_item = _resolveComponent("van-swipe-item");
  const _component_van_swipe = _resolveComponent("van-swipe");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_swipe, {
    class: "my-swipe",
    loop: false
  }, {
    indicator: _withCtx(_ref => {
      let {
        active
      } = _ref;
      return [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", {
        class: _normalizeClass(['gift-swiper-indicator-inner', active > 0 ? 'right' : 'left'])
      }, null, 2)])];
    }),
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.giftGroups, (group, index) => {
      return _openBlock(), _createBlock(_component_van_swipe_item, {
        key: index
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group, gift => {
          return _openBlock(), _createBlock(_component_gift_item, {
            key: gift.id,
            data: gift,
            onOnSelect: _ctx.handleSelect
          }, null, 8, ["data", "onOnSelect"]);
        }), 128))])]),
        _: 2
      }, 1024);
    }), 128))]),
    _: 1
  })]);
}