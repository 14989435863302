import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-69e90810"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "gift-footer"
};
const _hoisted_2 = {
  class: "gift-footer-total"
};
const _hoisted_3 = {
  class: "gift-footer-total-diamond"
};
const _hoisted_4 = ["src"];
const _hoisted_5 = {
  class: "gift-footer-total-diamond-number"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("img", {
    class: "gift-footer-img",
    src: `${_ctx.IMG_RESOURCE_DOMAIN}/mine-stone.webp`,
    alt: ""
  }, null, 8, _hoisted_4), _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.stoneNumber || '0'), 1)])]), _createElementVNode("div", {
    class: "gift-footer-send",
    onClick: _cache[0] || (_cache[0] = function () {
      return _ctx.handleSendGift && _ctx.handleSendGift(...arguments);
    })
  }, _toDisplayString(this.t('global.c-gift-popup#送礼物')), 1)]);
}