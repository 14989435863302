import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-abc07e0e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "iconfont",
  "aria-hidden": "true"
};
const _hoisted_2 = ["xlink:href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("svg", _hoisted_1, [_createElementVNode("use", {
    "xlink:href": `#${_ctx.icon}`
  }, null, 8, _hoisted_2)]);
}