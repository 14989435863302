function noop() {
}
function getSystemPlatformType() {
    if (/(iPhone|iPad|iPod|iOS)/i.test(window.navigator.userAgent)) {
        return 'ios';
    }
    if (/(Android)/i.test(window.navigator.userAgent)) {
        return 'android';
    }
    return '';
}
function isMobile() {
    return /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i.test(window.navigator.userAgent);
}
function isInFacebook() {
    return /FBSV|FBAV/.test(window.navigator.userAgent);
}
function getSystemLanguage() {
    return window.navigator.language || '';
}
function getBrowserType() {
    if (window.navigator.userAgent.indexOf('UCBrowser') !== -1) {
        return 'uc';
    }
    if (window.chrome || window.isChrome) {
        return 'chrome';
    }
    if (navigator.userAgent.indexOf('Safari') !== -1) {
        return 'safari';
    }
    return '';
}
function tryParseJSON(likeJsonContent) {
    const result = {};
    try {
        Object.assign(result, JSON.parse(likeJsonContent));
    }
    catch (e) {
        console.error(e);
    }
    return result;
}
function isFromFullScreenPWA() {
    const platform = getSystemPlatformType();
    if (platform === 'ios') {
        return window.navigator.standalone;
    }
    if (platform === 'android') {
        return window.matchMedia('(display-mode: standalone)').matches;
    }
    return false;
}
function getHistoryModeLocationQuery(field) {
    const query = {};
    const queryList = window.location.search.slice(1).split('&');
    for (let i = 0, len = queryList.length; i < len; i++) {
        const [key, value] = queryList[i].split('=');
        query[key] = value;
    }
    return field ? query[field] : query;
}
function querySerialize(query) {
    const resultList = [];
    for (let prop in query) {
        resultList.push(`${prop}=${query[prop]}`);
    }
    return resultList.join('&');
}
function forbidMobileScaleEvents() {
    // 禁用双指放大
    document.documentElement.addEventListener('touchstart', (event) => event.touches.length > 1 && event.preventDefault(), { passive: false });
    // 禁用双击放大
    const touchEndAndClickEndTimes = [0, 0];
    const touchEndAndClickEndHandler = (event, index) => {
        const now = Date.now();
        if (now - touchEndAndClickEndTimes[index] <= 300) {
            event.preventDefault();
        }
        touchEndAndClickEndTimes[index] = now;
    };
    document.documentElement.addEventListener('click', (event) => touchEndAndClickEndHandler(event, 1), { passive: false });
    document.documentElement.addEventListener('touchend', (event) => touchEndAndClickEndHandler(event, 0), { passive: false });
}
function debounce(fn, delay) {
    let timer;
    return (...args) => {
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(() => fn.apply(this, args), delay);
    };
}
function throttle(fn, delay) {
    let timer;
    return (...args) => {
        if (timer) {
            return;
        }
        fn.apply(this, args);
        timer = setTimeout(() => {
            timer = null;
        }, delay);
    };
}
function isURL(url) {
    return /^https?/.test(url);
}
function forceJumpLink(url, mode = 'history') {
    window.location.href = isURL(url) || mode === 'history' ? url : `${window.location.origin}/#${url}`;
}

function adapteFetchUrl(method, url, data) {
    return ['get', 'head', 'delete'].indexOf(method.toLocaleLowerCase()) > -1 ? `${url}?${querySerialize(data)}` : url;
}
function adapteFetchOptions(method, data, headers, abortController) {
    const options = {
        method: method.toLocaleLowerCase(),
        headers: Object.assign({ mode: 'cors', credentials: 'include' }, headers),
    };
    if (abortController) {
        options.signal = abortController.signal;
    }
    if (['get', 'head', 'delete'].indexOf(options.method) > -1) {
        return options;
    }
    options.body = /json/.test(options.headers['Content-Type'])
        ? JSON.stringify(data)
        : (/x-www-form-urlencoded/.test(options.headers['Content-Type']) ? querySerialize(data) : data);
    return options;
}
function requestAjax(method, url, data, headers, abortController) {
    if (!window.fetch) {
        return Promise.reject(new Error('fetch api not support'));
    }
    return window.fetch(adapteFetchUrl(method, url, data), adapteFetchOptions(method, data, headers, abortController))
        .then((response) => response.json())
        .catch((e) => Promise.reject(e));
}

export { debounce, forbidMobileScaleEvents, forceJumpLink, getBrowserType, getHistoryModeLocationQuery, getSystemLanguage, getSystemPlatformType, isFromFullScreenPWA, isInFacebook, isMobile, isURL, noop, querySerialize, requestAjax, throttle, tryParseJSON };
