import { defineComponent } from 'vue';
import { makeI18nT } from 'languages/index';
export default defineComponent({
  name: 'call-opecation',
  props: ['status'],
  emits: ['onCancelInvite', 'onRejectInvited', 'onAcceptInvited'],
  setup() {
    const {
      t
    } = makeI18nT();
    return {
      t
    };
  },
  computed: {
    statusText() {
      if (this.status === 'initiate') {
        return this.t('global.c-av-rtc-group#正在等待接听');
      }
      if (this.status === 'invited') {
        return this.t('global.c-av-rtc-group#邀请你进行视频通话');
      }
      return '';
    }
  },
  methods: {
    handleCancelInvite() {
      return console.log('发起通话之后、主动拒绝挂断') || this.$emit('onCancelInvite');
    },
    handleRejectInvited() {
      return console.log('拒绝邀请') || this.$emit('onRejectInvited');
    },
    handleAcceptInvited() {
      return console.log('接受邀请') || this.$emit('onAcceptInvited');
    }
  }
});