import { showToast } from 'vant';
import { makeI18nT } from 'languages/index';
import { defineComponent, ref, watchEffect } from 'vue';
import { usePayTypes } from './hooks/usePayTypes';
import { handleGeneralWayPayAjax } from '@/services/point';
import { getTouristUniqueDeviceId } from '@/providers/tourist';
import { FBR_EVENTS, FIREBASE_EVENTS, KK_EVENTS, KKReporter, FBReporter, FireBaseReporter } from '@/providers/reporter';
import PayBtn from './components/pay-btn.vue';
import PayItem from './components/pay-item.vue';
export default defineComponent({
  name: 'pay-popup',
  emits: ['update:modelValue'],
  props: ['modelValue', 'goodsInfo'],
  setup(props, _ref) {
    let {
      emit
    } = _ref;
    const {
      t
    } = makeI18nT();
    const [loading, visible] = [ref(false), ref(false)];
    const {
      payTypes,
      selectedPayId,
      selectedPayItem,
      handleUpdateSelectPayType
    } = usePayTypes();
    watchEffect(() => {
      visible.value = props.modelValue;
    });
    watchEffect(() => {
      emit('update:modelValue', visible.value);
      if (!visible.value) {
        setTimeout(() => {
          var _payTypes$value$;
          return handleUpdateSelectPayType((_payTypes$value$ = payTypes.value[0]) === null || _payTypes$value$ === void 0 ? void 0 : _payTypes$value$.id);
        }, 200);
      }
    });
    return {
      loading,
      visible,
      payTypes,
      selectedPayId,
      selectedPayItem,
      handleUpdateSelectPayType,
      t
    };
  },
  components: {
    'pay-btn': PayBtn,
    'pay-item': PayItem
  },
  computed: {
    priceText() {
      if (this.goodsInfo.price === 0 || this.goodsInfo.price.trim() === '') {
        return this.t('global.c-pay-popup#免费');
      }
      return `${this.goodsInfo.symbol}${this.goodsInfo.price}`;
    }
  },
  methods: {
    handleSelect(item) {
      this.handleUpdateSelectPayType(item.id);
    },
    handleToPay() {
      if (this.loading) {
        showToast({
          message: this.t('global.c-global#请求中')
        });
        return;
      }
      // googlepay 待定
      if (this.selectedPayItem.type === 'googlepay') {
        return;
      }
      this.handleJumpThirdWebsitePay();
    },
    handleJumpThirdWebsitePay() {
      this.loading = true;
      const windowReference = window.open('', '_blank');
      const params = {
        payType: this.selectedPayId,
        goodsId: this.goodsInfo.id,
        aaId: getTouristUniqueDeviceId()
      };
      return handleGeneralWayPayAjax(params).then(result => {
        if (result.code !== 0) {
          this.loading = false;
          windowReference.close();
          return;
        }
        this.$emit('toPay');
        this.$nextTick(() => {
          this.visible = false;
          this.loading = false;
          FBReporter(FBR_EVENTS.InitiateCheckout, params);
          FBReporter(FBR_EVENTS.CustomInitiateCheckout, params);
          FireBaseReporter(FIREBASE_EVENTS.BeginCheckout, params);
          FireBaseReporter(FIREBASE_EVENTS.CustomBeginCheckout, params);
          KKReporter(KK_EVENTS.INITIATE_CHECKOUT, params);
          windowReference.location.href = result.data.paymentUrl;
          this.$router.back();
        });
      });
    }
  }
});