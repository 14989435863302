module.exports = {
    // 全局
    'c-global#加载文案': 'Loading...',
    'c-global#请求中': 'Requesting...',
    'c-global#请使用其他方式尝试': 'Please try another way',

    // 组件
    'c-empty#空文案': 'No data. Please refresh',

    'c-scroll-list#列表数据为空': 'No more datas',

    'c-report-block-popup#举报': 'Report',
    'c-report-block-popup#拉黑': 'Block',
    'c-report-block-popup#色情': 'Erotic',
    'c-report-block-popup#暴力攻击': 'Violence/Verbal Assault',
    'c-report-block-popup#广告': 'Advertising',
    'c-report-block-popup#其他': 'Other',
    'c-report-block-popup#取消': 'Cancel',
    'c-report-block-popup#确认要拉黑XXX吗': 'Are you sure to block {nickname}?',
    'c-report-block-popup#如果拉黑，不能在看到对方的信息，确认要拉黑XXX吗': 'If you block {heOrHer}, you won\'t be able to see any information about {heOrHer}. Are you sure you want to block {heOrHer}?',

    'c-pay-popup#确认支付': 'Comfirm payment({ priceText })',
    'c-pay-popup#免费': 'Free',

    'c-gift-popup#目前钻石不足，请前往充值': 'Currently insufficient diamonds, please go to recharge',
    'c-gift-popup#去充值': 'recharge',
    'c-gift-popup#发送礼物中': 'sending gift',
    'c-gift-popup#你发送了一件礼物': 'you send a gift!',
    'c-gift-popup#送礼物': 'Send',

    'c-custom-modal#默认消息': 'message',
    'c-custom-modal#默认确定按钮': 'OK',

    'c-concat-customer-service#客服': 'Customer Service',
    'c-concat-customer-service#如果你有问题，请联系我们': 'lf you have issues, please contact us.',

    'c-av-rtc-group#25秒后，每分钟消耗60钻石': 'After 25 seconds，you will be charged 60',
    'c-av-rtc-group#每分钟': 'per minute.',
    'c-av-rtc-group#关注': 'Follow',
    'c-av-rtc-group#已关注': 'Followed',
    'c-av-rtc-group#喜欢来自': 'Likes from {nickname}',
    'c-av-rtc-group#正在等待接听': 'waiting for answer...',
    'c-av-rtc-group#邀请你进行视频通话': 'invites you to a video chat...',
    'c-av-rtc-group#请输入': 'please input',
    'c-av-rtc-group#请填写发送内容': 'Please fill in the content to send',

    // 页面
    'v-about-us#关于我们': 'About Us',
    'v-about-us#用户协议': 'User agreement',
    'v-about-us#隐私协议': 'Privacy policy',

    'v-accout-security#账号安全': 'Account Security',
    'v-accout-security#Email': 'Email',
    'v-accout-security#Phone': 'Phone',
    'v-accout-security#Google': 'Google',
    'v-accout-security#Facebook': 'Facebook',
    'v-accout-security#Apple': 'Apple',
    'v-accout-security#项目已经绑定': 'The {type} is bound',

    'v-agreement#用户协议': 'User Agreement',

    'v-anchor#关注': 'Follow',
    'v-anchor#已关注': 'unfollow',
    'v-anchor#已被关注': 'Follower',
    'v-anchor#这是我的自我介绍': 'This is my self introduction',

    'v-anchor-record#左滑查看下一个视频': 'Swipe left to view the next video',
    'v-anchor-record#上滑切换下一位': 'Swipe up to switch to the next',

    'v-blocked-list#拉黑列表': 'Blocked List',
    'v-blocked-list#解除拉黑操作成功': 'Opecation Success',
    'v-blocked-list#解除拉黑': 'unblock',

    'v-chat#通话已取消': 'Cancelled',
    'v-chat#通话已拒绝': 'Rejected',
    'v-chat#通话无应答': 'No answer',
    'v-chat#通话对方在忙': 'The other party is busy',
    'v-chat#你发送对方': 'You gave {nickname}',
    'v-chat#你接收对方': 'You received {nickname}',
    'v-chat#请输入': 'please input',
    'v-chat#请输入发送内容': 'Please fill in the content to send',
    'v-chat#昨天': 'yesterday',

    'v-completion#请上传头像': 'please upload your avatar',
    'v-completion#请输入个人签名': 'please enter your personal introduction',
    'v-completion#请输入你的昵称': 'please enter your nickname',
    'v-completion#请选择你的生日': 'please select your birthday',
    'v-completion#请填写你的昵称': 'Please fill in your nickname',
    'v-completion#你未满18岁': 'You are under 18 years old',
    'v-completion#下一步': 'Next',
    'v-completion#我是': 'I am',
    'v-completion#我正在寻找': 'I am looking for',
    'v-completion#男孩': 'Boy',
    'v-completion#女孩': 'Girl',
    'v-completion#请选择正确的的出生日期': 'Please select the correct date of birth',
    'v-completion#验证码错误': 'verification code error',

    'v-diamonds#页面标题': 'Store',
    'v-diamonds#余额不足,请先充值': 'Insufficient balance. Please top up first',
    'v-diamonds#免费': 'Free',
    'v-diamonds#领取成功': 'Received successfully',

    'v-email-register#绑定成功': 'bind success',
    'v-email-register#下一步': 'Next',
    'v-email-register#请输入你的邮箱': 'please enter your email',
    'v-email-register#请输入正确的邮箱': 'Please enter the correct email address',
    'v-email-register#请输入验证码': 'please enter verification code',


    'v-follower#页面标题': 'Follower',
    'v-following#页面标题': 'Following',

    'v-hot#在线': 'online',

    'v-index#你想要继续和男孩/女孩聊天吗': 'Do you want to continue chatting with the {target}?',
    'v-index#钻石不足': 'not enough diamonds',
    'v-index#轻触开始': 'Tap to start',
    'v-index#暂停': 'Pause',
    'v-index#下一个': 'Next',
    'v-index#等待对方选择': 'Waiting for the other party to choose',
    'v-index#接受': 'Accept',
    'v-index#等待': 'Waiting',
    'v-index#点击可筛选性别': 'Click to filter gender',
    'v-index#点击页面任意位置': 'Click anywhere on the page',
    'v-index#即可开始匹配': 'You can start matching',
    'v-index#价格': 'Price',
    'v-index#折扣价': 'Discount Price',
    'v-index#原价': 'Original',
    'v-index#充值': 'Recharge',
    'v-index#选择你想要视频聊天的性别' : 'Select the gender you want to video chat with',
    'v-index#男' : 'Men',
    'v-index#女' : 'Women',
    'v-index#男女' : 'Both',
    'v-index#选择您的性别偏好': 'choose your gender preference',
    'v-index#免费': 'Free',
    'v-index#性别筛选': 'Gender Screening',

    'v-login#请先阅读协议': 'Please read the agreement first',
    'v-login#快速开始': 'Quick Start',
    'v-login#苹果登陆': 'Apple Login',
    'v-login#GG登陆': 'Google',
    'v-login#FB登陆': 'Facebook',
    'v-login#手机登陆': 'Phone number',
    'v-login#邮箱登陆': 'Email',
    'v-login#其他登陆': 'Other Logins',
    'v-login#阅读并同意': 'read and agreed',
    'v-login#隐私协议': 'privacy policy',
    'v-login#和': 'and',
    'v-login#用户协议': 'agreement',

    'v-messages#视频消息': 'Video chat',
    'v-messages#礼物消息': 'Gift chat',
    'v-messages#客服消息': 'Customer service chat',
    'v-messages#WoooTeam': 'Wooo Team',
    'v-messages#刚刚': 'Just now',
    'v-messages#分钟前': 'minutes ago',
    'v-messages#小时前': 'hours ago',
    'v-messages#昨天': 'yesterday',
    'v-messages#开始并匹配': 'Simulan ang Pagtutugma',

    'v-mine#编辑资料': 'Edit Infornation',
    'v-mine#默认签名信息': 'Let life be beautiful like summer flowers and death like autumn leaves.',
    'v-mine#VIP到期时间': 'Expiration Date',
    'v-mine#VIP权益': 'Privileges',
    'v-mine#不限制聊天': 'Unlimited Messages',
    'v-mine#每月': 'per Month',
    'v-mine#获取更多粉丝': 'Get More Followers',
    'v-mine#OK': 'OK',
    'v-mine#钻石': 'Diamonds',
    'v-mine#VIP': 'VIP',
    'v-mine#客服': 'Customer Service',
    'v-mine#设置': 'Setting',
    'v-mine#视频': 'Video',
    'v-mine#审核中': 'Under review',
    
    'v-mobile-register#绑定成功': 'bind success',
    'v-mobile-register#下一步': 'Next',
    'v-mobile-register#请输入手机号': 'please enter your mobile',
    'v-mobile-register#请输入验证码': 'please enter verification code',

    'v-privacy-policy#隐私协议': 'Privacy Policy',

    'v-profile#页面标题': 'Profile',
    'v-profile#保存': 'Save',
    'v-profile#审核中': 'Under review',
    'v-profile#更新头像成功': 'update avatar success',
    'v-profile#昵称': 'Nick name',
    'v-profile#性别': 'Gender',
    'v-profile#寻找': 'Looking For',
    'v-profile#生日': 'birthdate',
    'v-profile#关于我': 'about me',
    'v-profile#关于我的默认签名': 'There are other things you want to say...',
    'v-profile#修改成功': 'Saved successfully',
    'v-profile#取消': 'Cancel',
    'v-profile#确认': 'Sure',
    'v-profile#男孩': 'Boy',
    'v-profile#女孩': 'Girl',
    'v-profile#昵称标题': 'Nickname',

    'v-records#页面标题': 'Records',
    'v-records#通话时长': 'Call duration',

    'v-setting#页面标题': 'Setting',
    'v-setting#登出': 'Sign out',
    'v-setting#账号安全': 'Account Security',
    'v-setting#黑名单': 'Blocked List',
    'v-setting#关于我们': 'About Us',
    'v-setting#邮箱': 'Email',
    'v-setting#电话': 'Phone',
    'v-setting#立刻绑定': 'Link Now',

    'v-system-not#页面标题': 'Wooo Team',
    'v-system-not#昨天': 'yesterday',

    'v-vip#现在购买': 'Buy Now',
    'v-vip#月份': 'month',
    'v-vip#给你想要的任何人发送消息': 'Send messages to anyone you want',
    'v-vip#每月600钻石': '{count} diamond per Month',
    'v-vip#获取更多粉丝': 'Get More Followers',
};
