import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-abccffde"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "scale-view"
};
const _hoisted_2 = {
  key: 2,
  class: "scale-not-drag"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_svg_iconfont = _resolveComponent("svg-iconfont");
  const _directive_drag = _resolveDirective("drag");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [!_ctx.isSmallWindow ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "scale-icon",
    onClick: _cache[0] || (_cache[0] = _withModifiers($event => _ctx.isSmallWindow = true, ["stop"]))
  }, [_createVNode(_component_svg_iconfont, {
    icon: "scale"
  })])) : !_ctx.disabled ? _withDirectives((_openBlock(), _createElementBlock("div", {
    key: 1,
    onVDragStart: _cache[1] || (_cache[1] = function () {
      return _ctx.handleMockClickOnDragStart && _ctx.handleMockClickOnDragStart(...arguments);
    }),
    onVDragMoving: _cache[2] || (_cache[2] = function () {
      return _ctx.handleMockClickOnDragMoving && _ctx.handleMockClickOnDragMoving(...arguments);
    }),
    onVDragEnd: _cache[3] || (_cache[3] = function () {
      return _ctx.handleFixedDragPosition && _ctx.handleFixedDragPosition(...arguments);
    })
  }, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)], 32)), [[_directive_drag, {
    handle: `#${_ctx.handleEleId}`
  }]]) : (_openBlock(), _createElementBlock("div", _hoisted_2, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)]))]);
}