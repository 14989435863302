import { showToast } from 'vant';
import { decode } from 'js-base64';
import { router } from '@/router/index';
import { getUserInfoFromStorage } from '@/utils/utils';
import { tryParseJSON } from 'packages/kk-tools-v1.0.0/dist/esm/index';
import { handleMatchResultReportAjax } from '@/services/notification';
import { handleEntryMatchAjax, handleExitMatchAjax, handleIsAllowToMatchAjax } from '@/services/match';
import { handleGetStoneNumberAjax } from '@/services/diamonds';

import * as Comlink from 'comlink';
import dayjs from 'dayjs';
import Bootstrapt from './bootstrap';

const { YimDispatchEvent } = window.KKYIM;

export const MatcherEvent = {
    MATCH_STOP: 'MATCH_STOP', MATCH_START: 'MATCH_START', MATCH_RESULT: 'MATCH_RESULT',
};

export default class Matcher {
    static instance = null;

    // eslint-disable-next-line no-return-assign
    static getMatchInstance = () => Matcher.instance || (Matcher.instance = new Matcher())

    constructor() {
        // 用户状态：1 主叫 2 被叫
        this.type = 0;

        // 匹配状态：readly、matching、waiting、calling; 准备匹配、匹配中、已匹配到等待对方响应、通话中
        this.status = 'readly';

        // 对方用户信息
        this.targetUserInfo = null;

        // 通知数据
        this.noticePayload = null;

        // 开始匹配数据
        this.startMatchPayload = null;

        // 后置钩子函数
        this.afterMatchHooks = [];

        // 前置钩子函数
        this.beforeMatchHooks = [];

        // yim 事件监听
        this.handleYIMEventListener();

        // 请求控制
        this.abortController = new AbortController();

        // 请求栈
        this.requestStack = [];

        // 延迟 timer
        this.delayTimers = [];
    }

    // 被叫 => yim 事件监听
    handleYIMEventListener() {
        this.handleMatchSystemNoticeWithCaller();
        this.handleMatchWhenVideoCallProcessEnd();
    }

    // 处理主叫的发送的系统通知
    handleMatchSystemNoticeWithCaller() {
        Bootstrapt.imInstance.handleListenYimEvents(YimDispatchEvent.SYS_NOTICE, async (data) => {
            // 被叫的接口响应较慢，会出现 status = matching 的情况
            console.warn('1005 系统通知===========>', data, this.noticePayload);

            if (data.id !== '1005' || ['matching', 'waiting'].indexOf(this.status) === -1) {
                return;
            }

            // 对方离开了本次匹配流程
            if (data.data.controlType.toString() === '4' && data.data.channelName === this.noticePayload?.channelName) {
                // 取消了 15 秒延迟等待
                await this.handleCancelNotDelayTimeout();

                // 离开提示
                showToast({ message: `${this.targetUserInfo.nickname} has left` });

                // 重置匹配状态
                if (this.requestStack.length === 0) {
                    this.handleUpdateMatchStatus('readly');
                }

                // 重新开始匹配
                await this.handleStartMatchAction(true);

                return;
            }

            // 当前匹配中了 -> 主叫通知到我匹配中了
            if (data.data.controlType.toString() === '3') {
                console.log('主叫通知我匹配中了', data);

                // 更新匹配者对方的信息
                this.noticePayload = data.data;
                this.targetUserInfo = tryParseJSON(decode(data.data.fromUserInfo));
                this.handleUpdateMatchStatus('waiting');

                // 发布匹配结果的通知
                Bootstrapt.eventer.emit(MatcherEvent.MATCH_RESULT, this.targetUserInfo);

                // 退出本次匹配
                await this.handleExitMatchAction();

                // 被叫 15 秒超时
                await this.handleGenerateNotDelayTimeout(() => {
                    if (this.status !== 'waiting') {
                        return;
                    }
                    this.handleLeaveMatchByCaller();
                }, 15 * 1000);
            }
        });
    }

    // 内部使用的匹配方法 useBeforeHooks -> 是否使用前置钩子
    async handleStartMatchAction(useBeforeHooks = false) {
        const isAllowMatch = await this.handleStartMatchActionCheck();

        // 未通过校验 -> 不允许匹配
        if (!isAllowMatch) {
            return false;
        }

        const isPassBeforeCheck = useBeforeHooks ? await this.handleInvokeInternalHooks('before') : await Promise.resolve(true);

        // 未通过前置钩子的校验
        if (!isPassBeforeCheck) {
            return false;
        }

        // 正式开始匹配流程
        // 保证请求的唯一性，需要清除之前的定时任务，避免再次出发匹配
        if (this.delayTimers.length > 0) {
            this.delayTimers = this.delayTimers.filter((item) => window.clearTimeout(item) && false);
        }

        try {
            // 推入请求栈
            this.requestStack.push(+new Date());

            this.handleUpdateMatchStatus('matching');

            // 实际开始匹配
            console.warn('实际开始匹配: ', dayjs().format('YYYY-MM-DD HH:mm:ss'), this.requestStack[0], this.abortController.signal.aborted);

            const matchAjaxResponse = await handleEntryMatchAjax(this.startMatchPayload, this.abortController);

            console.warn('实际匹配响应: ', dayjs().format('YYYY-MM-DD HH:mm:ss'), this.requestStack[0], matchAjaxResponse);

            // 推出请求栈
            this.requestStack.pop();

            // 接口响应超时 -> 开启下一轮匹配
            if (matchAjaxResponse.code === -1) {
                await this.handleExitMatchAction();
                await this.handleStartMatchAction(true);
                return false;
            }

            // 接口响应异常 -> 退出匹配
            if (!matchAjaxResponse || matchAjaxResponse.code !== 0) {
                await this.handleExitMatchAction();
                return false;
            }

            // 接口正常响应了
            return !!(this.handleMatchResultDispatchStrategies(matchAjaxResponse.data) || true);
        } catch (e) {
            console.log(e);
            this.requestStack.pop();

            return false;
        }
    }

    // 开始匹配前的校验：当前在匹配中或者当前不在匹配页 -> 不允许匹配
    handleStartMatchActionCheck() {
        return Promise.resolve(this.requestStack.length === 0 && window.location.pathname === '/index' && ['readly', 'matching'].indexOf(this.status) > -1).then((result) => result && this.getIsAllowMatch());
    }

    // 是否允许匹配
    getIsAllowMatch() {
        if (!this.startMatchPayload) {
            return Promise.resolve(false);
        }
        return Promise.all([handleGetStoneNumberAjax(), handleIsAllowToMatchAjax(this.startMatchPayload)]).then((results) => {
            if (results[0].code !== 0 || results[1].code !== 0) {
                return false;
            }
            if (!results[1].data.allow && results[1].data.coin > results[0].data.stone) {
                router.push('/diamonds?tip=1');
            }
            return results[1].data.allow;
        });
    }

    // 退出匹配
    handleExitMatchAction() {
        // 清除之前的定时任务
        if (this.delayTimers.length > 0) {
            this.delayTimers = this.delayTimers.filter((item) => window.clearTimeout(item) && false);
        }

        // 在通话状态，自动调用了退出匹配 -> 例如再次返回了匹配页面
        if (this.status === 'calling') {
            return Promise.resolve(true);
        }

        // 如果当前已经在匹配请求中 -> 取消当前请求
        if (this.requestStack.length > 0) {
            console.error('取消当前请求', this.requestStack[0]);
            this.abortController.abort();
            this.abortController = new AbortController();
        }

        // 如果当前未匹配到人，设置到初始状态 -> readly、waiting 这两个状态保持即可 (readly、matching、waiting)
        if (this.status === 'matching') {
            this.status = 'readly';
        }

        console.warn('实际退出匹配', dayjs().format('YYYY-MM-DD HH:mm:ss'));

        return handleExitMatchAjax()
            .then((result) => {
                console.warn('实际退出匹配响应', dayjs().format('YYYY-MM-DD HH:mm:ss'), result);
                return result.code === 0;
            })
            .then(() => this.handleInvokeInternalHooks('after'));
    }

    // 结束视频通话流程（例如挂断、拒绝等）后 -> 开启下一次匹配
    handleMatchWhenVideoCallProcessEnd() {
        // 结束视频通话流程 -> 匹配成功之后，直接挂断
        Bootstrapt.imInstance.handleListenYimEvents(YimDispatchEvent.XL_END_VIDEO_CALL, async (invitedInfos) => {
            console.warn('==============>', this.status, window.location.pathname, invitedInfos, this.noticePayload);

            // channelName 校验：异步通知不稳定，添加同一个 channelName 校验
            const { attachExt = null } = invitedInfos || {};

            if (!attachExt || !attachExt.channelName || attachExt.channelName !== this.noticePayload?.channelName) {
                return;
            }

            // 本次的流程
            const originStatus = this.status;

            // 通话流程结束，重置匹配状态
            if (this.requestStack.length === 0) {
                this.handleUpdateMatchStatus('readly');
            }

            // waiting：对方主叫，当前拒绝 -> 开始下一次匹配
            // calling
            //      1. 自己主叫，对方拒绝，结束了通话流程 -> 通过系统通知完成
            //      2. 正常匹配通话挂断（正常挂断、钻石不足挂断）
            if ((originStatus === 'waiting' || originStatus === 'calling') && window.location.pathname === '/index') {
                // 钻石不足会有路由跳转，添加异步触发
                this.delayTimers.push(setTimeout(async () => {
                    await this.handleStartMatchAction(true);
                }, 200));
            }
        });
    }

    // 绑定匹配钩子函数
    handleBindMatchHooks(type, funcs) {
        if (!this.handleCheckHooksIsExist(type)) {
            return;
        }
        this[`${type}MatchHooks`] = funcs;
    }

    // 检查钩子是否存在
    handleCheckHooksIsExist(type) {
        if (['before', 'after'].indexOf(type) === -1) {
            return console.error('bind hooks type error') || false;
        }
        return true;
    }

    // 开始匹配 -> 供外部调用
    handleStartMatching(payload) {
        // 记录匹配参数
        this.startMatchPayload = payload;

        // 防止多次进入匹配
        return this.status !== 'readly' ? Promise.resolve(true) : this.handleStartMatchAction(true);
    }

    // 执行钩子操作
    handleInvokeInternalHooks(type) {
        if (!this.handleCheckHooksIsExist(type)) {
            return Promise.reject(new Error('bind hooks type error'));
        }
        return this[`${type}MatchHooks`].reduce((prev, next) => prev.then((result) => result && next()), Promise.resolve(true));
    }

    // 更新 match 状态
    // readly、matching、waiting、calling;
    handleUpdateMatchStatus(status) {
        this.status = status;

        const statusEventMap = {
            readly: MatcherEvent.MATCH_STOP,
            calling: MatcherEvent.MATCH_STOP,
            matching: MatcherEvent.MATCH_START,
        };

        if (status === 'readly' && this.noticePayload) {
            this.noticePayload = null;
        }

        if (typeof statusEventMap[status] !== 'undefined') {
            Bootstrapt.eventer.emit(statusEventMap[status]);
        }
    }

    // 停止匹配
    handleStopMatching() {
        return this.handleExitMatchAction();
    }

    // 处理响应结果的分发处理策略
    handleMatchResultDispatchStrategies(data) {
        // 状态控制
        if (this.status !== 'matching') {
            return Promise.resolve(false);
        }

        // 未匹配到 -> 继续匹配
        if (data.result === 3) {
            return this.handleStartMatchAction();
        }

        // 接口等待 -> 1 min 后在匹配
        if (data.result === 4) {
            this.delayTimers.push(setTimeout(() => this.handleStartMatchAction(), 60 * 1000));
            return Promise.resolve(false);
        }

        // 标记主叫、被叫
        this.type = data.result;

        // 匹配为主叫
        if (data.result === 1) {
            this.handleUpdateMatchStatus('waiting');

            Bootstrapt.eventer.emit(MatcherEvent.MATCH_RESULT, data.calleeUser);
            return this.handleExitMatchAction().then(() => this.handleSendMatchInfoByCaller(data));
        }

        // 匹配为被叫
        // 被叫的返回有时候是不可靠的 - 被叫的等待状态以主叫通知为准
        // if (data.result === 2) {
        //     this.handleUpdateMatchStatus('waiting');
        //     return this.handleExitMatchAction();
        // }

        return Promise.resolve(true);
    }

    // 响应匹配中主叫 => 主叫发送系统通知被叫
    handleSendMatchInfoByCaller(data) {
        const payload = this.handleGenerateNoticePayload(data);
        return Bootstrapt.imInstance.handleSendCustomSysMessage('p2p', this.targetUserInfo?.imId, payload).then(async () => {
            await this.handleGenerateNotDelayTimeout(() => {
                if (this.status !== 'waiting') {
                    return;
                }
                this.handleLeaveMatchByCaller();
            }, 15 * 1000);
        });
    }

    // 生成延迟任务
    async handleGenerateNotDelayTimeout(callback, delay) {
        await Bootstrapt.webWorker.handleGenerateNotDelayTimeout(Comlink.proxy(() => callback()), delay);
    }

    // 取消延迟任务
    async handleCancelNotDelayTimeout() {
        await Bootstrapt.webWorker.handleCancelNotDelayTimeout();
    }

    // 生成通知消息体
    handleGenerateNoticePayload(data) {
        // 主叫生成通知信息
        if (data) {
            const { callerUser = {}, calleeUser = {}, callerUserEncoded, stepOne, stepTwo } = data;

            this.noticePayload = {
                channelName: `m${callerUser.userId}${calleeUser.userId}${dayjs().unix()}`, controlType: 3, fromUserId: callerUser.userId, fromUserInfo: callerUserEncoded, stepOne, stepTwo,
            };
            this.targetUserInfo = calleeUser;

            return {
                id: '1005', data: this.noticePayload,
            };
        }
        // 被叫生成通知信息
        return {
            id: '1005', data: { ...(this.noticePayload || {}), controlType: 4 },
        };
    }

    // 匹配中 => 接受
    // userId 比较大小，大的拨打通话
    async handleAcceptMatchResult() {
        const userInfo = getUserInfoFromStorage();

        // 取消 15 秒的等待
        await this.handleCancelNotDelayTimeout();

        // 上报匹配结果
        handleMatchResultReportAjax({ operate: 1, targetImId: this.targetUserInfo.imId, channelId: this.noticePayload?.channelName });

        // 拨打电话
        if (userInfo.userId > this.targetUserInfo.userId) {
            return Bootstrapt.imInstance.handleProposeVoiceOrVideoCall(2, this.targetUserInfo, true, this.noticePayload?.channelName)
                .then(() => {
                    Bootstrapt.imInstance.handleListenYimOnceEvents(YimDispatchEvent.XL_ENTRY_VIDEO_CALL, () => {
                        this.handleUpdateMatchStatus('calling');
                    });
                })
                .catch((e) => {
                    console.log(e);
                });
        }

        // 接受邀请
        const invitedInfos = Bootstrapt.imInstance.getInvitedInfos();

        // 没有进入音视频流程 -> 接收到邀请在重新执行流程
        if ([1, 2].indexOf(invitedInfos.type) === -1) {
            Bootstrapt.imInstance.handleListenYimOnceEvents(YimDispatchEvent.XL_RECIVE_VIDEO_INVITED, () => {
                this.handleAcceptMatchResult();
            });
            return Promise.resolve(true);
        }

        return Bootstrapt.imInstance.handleReviceInvitedAndJoin().then(() => {
            this.handleUpdateMatchStatus('calling');
        });
    }

    // 匹配中 => 不接受主动离开 next
    async handleLeaveMatchByCaller() {
        const invitedInfos = Bootstrapt.imInstance.getInvitedInfos();

        // 取消 15 秒的等待
        await this.handleCancelNotDelayTimeout();

        // 上报匹配结果
        handleMatchResultReportAjax({ operate: 2, targetImId: this.targetUserInfo?.imId, channelId: this.noticePayload?.channelName });

        // 已经进入了音视频流程 -> 拒绝邀请
        if ([1, 2].indexOf(invitedInfos.type) > -1) {
            Bootstrapt.imInstance.handleRejectInvite();
        }

        return Bootstrapt.imInstance.handleSendCustomSysMessage('p2p', this.targetUserInfo?.imId, this.handleGenerateNoticePayload())
            .then(() => {
                if (this.requestStack.length === 0) {
                    this.handleUpdateMatchStatus('readly');
                }
                return this.handleStartMatchAction(true);
            });
    }

    getStatus() {
        return this.status;
    }

    getTargetUserInfo() {
        return this.targetUserInfo;
    }

    getStartMatchPayload() {
        return this.startMatchPayload;
    }
}
