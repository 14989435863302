/* eslint-disable no-restricted-syntax */
import { request } from '@/utils/network';

// 解除拉黑用户
export function handUnBlockUserAjax(params) {
    return request('post', '/risk/unblock-user', params);
}

// 拉黑用户列表
export function handBlockUserListUpAjax(params) {
    return request('post', '/risk/block-user-list', params);
}

// 举报用户
export function handleReportUserAjax(params) {
    const formData = new FormData();

    // eslint-disable-next-line guard-for-in
    for (const prop in params) {
        formData.append(prop, params[prop]);
    }
    return request('post', '/risk/report-user', formData, { 'Content-Type': 'multipart/form-data' });
}

// 拉黑用户
export function handleBlockUserAjax(params) {
    return request('post', '/risk/block-user', params);
}
