import { makeI18nT } from 'languages/index';
import { defineComponent, ref, watchEffect } from 'vue';
import { handleReportUserAjax, handleBlockUserAjax } from '@/services/risk';
import Bootstrap from '@/providers/bootstrap';
import CustomPopup from '@/components/custom-popup/index.vue';
const {
  t
} = makeI18nT();
const defaultOpecations = [{
  type: '1',
  text: t('global.c-report-block-popup#举报')
}, {
  type: '2',
  text: t('global.c-report-block-popup#拉黑')
}];
const defaultReportItems = [{
  type: '1',
  text: t('global.c-report-block-popup#色情')
}, {
  type: '2',
  text: t('global.c-report-block-popup#暴力攻击')
}, {
  type: '3',
  text: t('global.c-report-block-popup#广告')
}, {
  type: '4',
  text: t('global.c-report-block-popup#其他')
}];
const defaultButtons = [{
  type: 'cancel',
  text: t('global.c-report-block-popup#取消')
}, {
  type: 'ok',
  text: t('global.c-report-block-popup#拉黑')
}];
export default defineComponent({
  name: 'report-block-popup',
  props: {
    modelValue: {
      type: Boolean
    },
    targetUserInfo: {
      type: Object
    },
    hasBlock: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update:modelValue', 'ok', 'cancel'],
  setup(props, _ref) {
    let {
      emit
    } = _ref;
    const [visible, buttons] = [ref(props.modelValue), ref(defaultButtons)];
    watchEffect(() => {
      visible.value = props.modelValue;
    });
    watchEffect(() => emit('update:modelValue', visible.value));
    return {
      visible,
      buttons,
      t
    };
  },
  data() {
    return {
      type: this.hasBlock ? '1' : '2',
      opecations: this.hasBlock ? defaultOpecations : defaultOpecations.slice(0, 1),
      reportItems: defaultReportItems
    };
  },
  components: {
    'custom-popup': CustomPopup
  },
  computed: {
    adaptedOpecations() {
      if (this.type === '3') {
        return [];
      }
      return this.type === '1' ? this.opecations : this.reportItems;
    }
  },
  methods: {
    handleTiggerItem(type, subType) {
      if (type === '1') {
        this.type = (+subType + 1).toString();
      }
      if (type === '2') {
        this.handleTriggerReport(subType);
      }
    },
    handleTriggerReport(subType) {
      const params = {
        type: subType,
        content: '',
        certificateFile: null,
        position: 1,
        userStringId: this.targetUserInfo.userStringId
      };
      const targetSessionId = Bootstrap.imInstance.handleGetSessionIdByTargetImId(this.targetUserInfo.imId);
      return handleReportUserAjax(params).then(result => {
        if (result.code !== 0) {
          return;
        }
        // 清除本地会话
        Bootstrap.imInstance.handleRemoveSessionBySessionId(targetSessionId);
        this.visible = false;
        this.$emit('ok', this.type);
      });
    },
    handleCancel() {
      this.visible = false;
      this.$emit('cancel', this.type);
    },
    handleOk() {
      return handleBlockUserAjax({
        userStringId: this.targetUserInfo.userStringId
      }).then(result => {
        if (result.code !== 0) {
          return;
        }
        this.visible = false;
        this.$emit('ok', this.type);
      });
    }
  },
  watch: {
    visible(newValue) {
      if (newValue) {
        return;
      }
      this.type = this.hasBlock ? '1' : '2';
    }
  }
});