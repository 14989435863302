import { h, render } from 'vue';
import { USER_INFO } from '@/utils/consts';

export function getUserInfoFromStorage() {
    const [userInfo, userInfoString] = [{}, window.localStorage.getItem(USER_INFO) || null];

    try {
        Object.assign(userInfo, JSON.parse(userInfoString));
    } catch (e) {
        console.log(e);
    }
    return userInfo;
}

export function handleTryParseJSON(content) {
    const data = {};

    try {
        Object.assign(data, JSON.parse(content));
    } catch (e) {
        console.log(e);
    }
    return data;
}

export function handleTriggerRenderToBody(Component, props = {}, id = undefined) {
    const [element, instance] = [document.createElement('div'), h(Component, props)];

    element.id = id || `add-to-body-${Math.random()}`;

    document.body.appendChild(element);

    render(instance, element);

    const closer = () => {
        render(null, element);
        document.body.removeChild(element);
    };

    return { closer, instance };
}

export function handleDataSourceToDataSourceGroups(dataSource, groupLen) {
    const results = [];

    for (let i = 0, len = dataSource.length; i < len; i++) {
        if (results.length === 0 || results[results.length - 1].length === groupLen) {
            results.push([dataSource[i]]);
        } else {
            results[results.length - 1].push(dataSource[i]);
        }
    }
    return results;
}

export function handleGetScrollTop() {
    if (document.documentElement && typeof document.documentElement.scrollTop !== 'undefined') {
        return document.documentElement.scrollTop || 0;
    }
    if (document.body) {
        return document.body.scrollTop || 0;
    }
    return 0;
}

export function handleSetScrollTop(scrollTop) {
    if (document.documentElement && typeof document.documentElement.scrollTop !== 'undefined') {
        document.documentElement.scrollTop = scrollTop;
        return;
    }

    if (document.body) {
        document.body.scrollTop = scrollTop;
    }
}

export function handleAsyncInjectScript(scriptUrl, id) {
    return new Promise((resolve) => {
        const script = document.createElement('script');

        if (document.getElementById(id)) {
            resolve(true);
            return;
        }

        script.id = id;
        script.src = scriptUrl;

        script.addEventListener('load', () => resolve(true));

        document.head.appendChild(script);
    });
}

export function handleForbidMobilePullFreshWithSafari() {
    let startY = 0;

    document.addEventListener('touchstart', (event) => {
        startY = event.layerY;
    }, { passive: false });

    document.addEventListener('touchmove', (event) => {
        if (handleGetScrollTop() <= 0 && event.layerY > startY) {
            event.preventDefault();
        }
    }, { passive: false });
}

export function handleRandomString(len) {
    const result = [];
    const letters = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

    for (let i = len; i > 0; --i) {
        result.push(letters[Math.floor(Math.random() * letters.length)]);
    }

    return result.join('');
}
